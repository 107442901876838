import { store } from "../../store/store.js";
import socket from "../../socket/Socket.js";
import { toast } from "react-toastify";
import { translations } from "./pages/components/Draggable/Draggable.component.jsx";

const appId = 62094;
// const appId = 39953;
const selectedAccount = JSON.parse(localStorage.getItem("selectedAccount"));
const chartType = localStorage.getItem("chartType");
export let derivWS = null;
const connectionDStatus = document.getElementById("socketDeriv");
const subscriptions = new Map();
var reconnectInterval = 1000;
let activeSymbol = {};
let symbolsCache = null;
let oldPeriod = null;
let hasCalledDerivWS = false;
let processedContracts = new Set();
let accumHighLine = null;
let accumLowLine = null;
let reconnectAttempts = 0;
let maxReconnectAttempts = 5;
let timeInterval = null;
let wsReconnectTimeout;
const MAX_RECONNECT_DELAY = 10000;
const INITIAL_RECONNECT_DELAY = 1000;
const historyCache = new Map();
let lastRanges = [];

// Queue for history requests
const historyRequestQueue = [];
let isProcessingQueue = false;

// Throttling variables
let lastRequestTime = 0;
const MIN_REQUEST_INTERVAL = 500; // Minimum time between requests in milliseconds

// Retry variables
const MAX_RETRIES = 3;
const RETRY_DELAY_BASE = 500;

function initDerivWSConnection(reconnect = false) {
	if (derivWS && derivWS.readyState !== 1) {
		derivWS.close();
	}

	derivWS = new WebSocket("wss://ws.derivws.com/websockets/v3?app_id=" + appId);
	store.getState().setDerivWS(derivWS);

	derivWS.addEventListener("open", async (event) => {
		connectionDStatus.style.backgroundColor = "green";
		clearTimeout(wsReconnectTimeout);
		clearInterval(timeInterval);
		reconnectAttempts = 0;
		reconnectInterval = INITIAL_RECONNECT_DELAY;

		if (selectedAccount && selectedAccount.token) {
			const authMessage = await JSON.stringify({
				authorize: selectedAccount.token,
				passthrough: { balance: true },
			});
			derivWS.send(authMessage);
		}
		derivListener();
		startHeartbeat();

		if (reconnect) {
			const barsData = store.getState().barsData;
			const updatedBarsData = {
				...barsData,
				from: barsData.from + 500,
				to: barsData.to + 500,
				firstTime: false,
			};
			getHistoryDWS(updatedBarsData, true);
		}
	});

	derivWS.addEventListener("close", handleClose);
	derivWS.addEventListener("error", handleError);
	wsReconnectTimeout = null;
}

function handleClose(event) {
	connectionDStatus.style.backgroundColor = "red";
	clearTimeout(wsReconnectTimeout);

	if (reconnectAttempts < maxReconnectAttempts) {
		reconnectInterval = Math.min(reconnectInterval * 1.5, MAX_RECONNECT_DELAY);
		reconnectAttempts++;

		wsReconnectTimeout = setTimeout(() => {
			if (derivWS && derivWS.readyState !== 1) {
				if (derivWS.readyState === 3) {
					initDerivWSConnection(true);
				} else if (derivWS.readyState === 0 || derivWS.readyState === 2) {
					setTimeout(() => {
						initDerivWSConnection(true);
					}, 2000);
				}
			}
		}, reconnectInterval);
	} else {
		console.log("Max reconnection attempts reached");
	}
}

function handleError(error) {
	console.error("WebSocket error:", error);
	connectionDStatus.style.backgroundColor = "red";
	clearTimeout(wsReconnectTimeout);

	if (reconnectAttempts < maxReconnectAttempts) {
		reconnectInterval = Math.min(reconnectInterval * 1.5, MAX_RECONNECT_DELAY);
		reconnectAttempts++;

		wsReconnectTimeout = setTimeout(() => {
			if (derivWS && derivWS.readyState !== 1) {
				if (derivWS.readyState === 3) {
					initDerivWSConnection(true);
				} else if (derivWS.readyState === 0 || derivWS.readyState === 2) {
					setTimeout(() => {
						initDerivWSConnection(true);
					}, 2000);
				}
			}
		}, reconnectInterval);
	} else {
		console.log("Max reconnection attempts reached");
	}
}

initDerivWSConnection();

window.checkingDerivConnection = () => {
	if (reconnectAttempts < maxReconnectAttempts) {
		reconnectInterval = Math.min(reconnectInterval * 1.5, MAX_RECONNECT_DELAY);
		reconnectAttempts++;

		wsReconnectTimeout = setTimeout(() => {
			if (derivWS && derivWS.readyState !== 1) {
				if (derivWS.readyState === 3) {
					initDerivWSConnection(true);
				} else if (derivWS.readyState === 0 || derivWS.readyState === 2) {
					setTimeout(() => {
						initDerivWSConnection(true);
					}, 2000);
				}
			}
		}, reconnectInterval);
	} else {
		console.log("Max reconnection attempts reached");
	}
};

const derivListener = async () => {
	derivWS.addEventListener("message", async (event) => {
		try {
			const receivedMessage = JSON.parse(event.data);
			if (receivedMessage.msg_type !== "ohlc") {
				// console.log("DERIV SOCKET", receivedMessage);
			}

			if (receivedMessage.error) {
				store.getState().setLoading(false);
				toast.error(translations[receivedMessage.error.message], {
					position: "top-right",
					autoClose: 2000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					theme: "dark",
				});
				return;
			}

			if (receivedMessage.msg_type === "ohlc") {
				const chartType = localStorage.getItem("chartType");
				const ohlc = receivedMessage.ohlc;

				if (ohlc.symbol === activeSymbol.symbol && ohlc.granularity === activeSymbol.period) {
					const symbol = ohlc.symbol;
					const period = ohlc.granularity;
					const subsKey = symbol + "-" + period;
					const subs = subscriptions.get(subsKey);
					// console.log("DERIV SUBS", subs);
					if (!subs) {
						console.warn(`No subscription found for symbol: ${symbol}`);
						return;
					}

					let epochTime = (ohlc.epoch * 1000).toString();

					if (epochTime.length < 13) {
						const difference = 13 - epochTime.length;
						epochTime = epochTime + "0".repeat(difference);
					}

					const formattedTimeString = epochTime + ".000";
					const formattedTime = parseFloat(formattedTimeString);
					let currentBar = {
						epoch: epochTime,
						time: formattedTime,
						open: parseFloat(ohlc.open),
						close: parseFloat(ohlc.close),
						high: parseFloat(ohlc.high),
						low: parseFloat(ohlc.low),
					};

					// if (chartType === "Opciones") {
					// 	subs.onRealtimeCallback(currentBar);
					// } else {
					subs.onRealtimeCallback(currentBar);
					// }

					store.getState().setLastBar(ohlc);
				}
			} else if (receivedMessage.msg_type === "authorize") {
				if (receivedMessage.echo_req.passthrough) {
					if (receivedMessage.echo_req.passthrough.contractId) {
						const data = receivedMessage.echo_req.passthrough;
						const proposalMessage = JSON.stringify({
							proposal_open_contract: 1,
							contract_id: data.contractId,
							subscribe: 1,
						});
						derivWS.send(proposalMessage);
					} else if (receivedMessage.echo_req.passthrough.balance) {
						const balanceMessage = JSON.stringify({
							balance: 1,
							subscribe: 1,
						});
						derivWS.send(balanceMessage);
					} else if (receivedMessage.echo_req.passthrough.history) {
						const historyMessage = JSON.stringify({
							proposal_open_contract: 1,
							subscribe: 1,
							passthrough: { history: true },
						});
						derivWS.send(historyMessage);
					}
				}
			} else if (receivedMessage.msg_type === "proposal_open_contract") {
				// console.log("PROPOSAL OPEN CONTRACT", receivedMessage.proposal_open_contract);

				if (receivedMessage?.passthrough?.history) {
					store.getState().setPositionsData(receivedMessage.proposal_open_contract);
					return;
				} else if (receivedMessage.passthrough?.drawPositions) {
					const contract = receivedMessage.proposal_open_contract;
					window.drawEntry(
						contract.entry_tick_time,
						contract.entry_tick,
						contract.exit_tick_time,
						contract.exit_tick,
						contract.contract_type,
						contract.profit
					);
				}

				const optionType = await localStorage.getItem("optionType");

				if (
					(chartType === "Opciones" &&
						receivedMessage.proposal_open_contract.contract_type !== "MULTUP" &&
						receivedMessage.proposal_open_contract.contract_type !== "MULTDOWN") ||
					(receivedMessage.proposal_open_contract.contract_type !== "MULTUP" &&
						receivedMessage.proposal_open_contract.contract_type !== "MULTDOWN")
				) {
					const tvWidget = store.getState().chart;
					let lastDrawnTick = null;
					const proposal = receivedMessage.proposal_open_contract;
					let barrierLine = null;
					let startLine = null;

					if (!processedContracts.has(proposal.contract_id)) {
						processedContracts.add(proposal.contract_id);

						proposal.tick_stream.forEach((tick, index) => {
							const resultColor = "orange";

							if (lastDrawnTick) {
								tvWidget.activeChart().createMultipointShape(
									[
										{ time: lastDrawnTick.epoch, price: lastDrawnTick.tick },
										{ time: tick.epoch, price: tick.tick },
									],
									{
										shape: "trend_line",
										lock: true,
										disableSelection: true,
										disableSave: true,
										disableUndo: true,
										text: `Tick ${index}`,
										overrides: {
											linecolor: "orange",
											linewidth: 5,
											linestyle: 1, // Changed to dotted line
											statsPosition: 3,
											textcolor: "white",
											fontsize: 12,
											bold: true,
										},
									}
								);

								if (optionType !== "accumulators") {
									//BARRIER LINE
									if (!barrierLine) {
										barrierLine = tvWidget.activeChart().createMultipointShape(
											[
												{ time: proposal.entry_tick_time, price: Number(proposal.barrier) },
												{ time: proposal.entry_tick_time + proposal.tick_count, price: Number(proposal.barrier) },
											],
											{
												shape: "trend_line",
												lock: true,
												disableSelection: true,
												disableSave: true,
												disableUndo: true,
												overrides: {
													linecolor: resultColor,
													linewidth: 0.5,
													statsPosition: 3,
													textcolor: "white",
													fontsize: 12,
													bold: true,
												},
											}
										);
									}

									//START LINE
									if (!startLine) {
										startLine = tvWidget.activeChart().createMultipointShape(
											[
												{ time: proposal.entry_tick_time, price: proposal.entry_spot },
												{ time: proposal.entry_tick_time, price: Number(proposal.barrier) },
											],
											{
												shape: "trend_line",
												lock: true,
												disableSelection: true,
												disableSave: true,
												disableUndo: true,
												overrides: {
													linecolor: resultColor,
													linestyle: 2, // Dotted line
													linewidth: 1,
												},
											}
										);
									}
								} else {
									// tvWidget.activeChart().createShape(
									// 	{ time: proposal.sell_time, price: proposal.sell_spot },
									// 	{
									// 		shape: "balloon",
									// 		text: proposal.profit,
									// 		overrides: {
									// 			backgroundColor: resultColor,
									// 			color: "white",
									// 			fontsize: 20,
									// 			bold: true,
									// 			zIndex: 100,
									// 		},
									// 		zOrder: "top",
									// 	}
									// );
								}
							}
							lastDrawnTick = tick;
						});

						const expireTime = proposal.expiry_time;
						const currentTime = proposal.current_spot_time;

						if (expireTime === currentTime) {
							const forgetMessage = JSON.stringify({
								forget: proposal.id,
								passthrough: { contractId: proposal.contract_id },
							});
							derivWS.send(forgetMessage);

							const sellExpiredMessage = JSON.stringify({
								sell_expired: 1,
								passthrough: { contractId: proposal.contract_id },
							});
							derivWS.send(sellExpiredMessage);
						}
					} else {
						if (proposal.status === "won" || proposal.status === "lost") {
							const forgetMessage = JSON.stringify({ forget: proposal.id });
							derivWS.send(forgetMessage);
							const resultColor = proposal.status === "won" ? "green" : "red";
							const optionType = await localStorage.getItem("optionType");

							if (optionType !== "accumulators") {
								//BARRIER LINE
								barrierLine = tvWidget.activeChart().createMultipointShape(
									[
										{ time: proposal.entry_tick_time, price: Number(proposal.barrier) },
										{ time: proposal.expiry_time, price: Number(proposal.barrier) },
									],
									{
										shape: "trend_line",
										lock: true,
										disableSelection: true,
										disableSave: true,
										disableUndo: true,
										overrides: {
											linecolor: resultColor,
											linewidth: 0.5,
											statsPosition: 3,
											textcolor: "white",
											fontsize: 12,
											bold: true,
										},
									}
								);

								//START LINE
								startLine = tvWidget.activeChart().createMultipointShape(
									[
										{ time: proposal.entry_tick_time, price: proposal.entry_spot },
										{ time: proposal.entry_tick_time, price: Number(proposal.barrier) },
									],
									{
										shape: "trend_line",
										lock: true,
										disableSelection: true,
										disableSave: true,
										disableUndo: true,
										overrides: {
											linecolor: resultColor,
											linestyle: 2, // Dotted line
											linewidth: 1,
										},
									}
								);

								//END LINE
								tvWidget.activeChart().createMultipointShape(
									[
										{ time: proposal.sell_time, price: Number(proposal.barrier) },
										{ time: proposal.sell_time, price: proposal.sell_spot },
									],
									{
										shape: "trend_line",
										lock: true,
										disableSelection: true,
										disableSave: true,
										disableUndo: true,
										overrides: {
											linecolor: resultColor,
											linestyle: 2, // Dotted line
											linewidth: 1,
										},
									}
								);
							}
							//BALLON TEXT
							tvWidget.activeChart().createShape(
								{ time: proposal.sell_time, price: proposal.sell_spot },
								{
									shape: "balloon",
									text: proposal.profit,
									lock: true,
									disableSelection: true,
									disableSave: true,
									disableUndo: true,
									overrides: {
										backgroundColor: resultColor,
										color: "white",
										fontsize: 20,
										bold: true,
										zIndex: 100,
									},
									zOrder: "top",
								}
							);

							hasCalledDerivWS = false;
						}
						processedContracts.delete(proposal.contract_id);
					}
				}
			} else if (receivedMessage.msg_type === "sell_expired") {
				const contractId = receivedMessage.echo_req.passthrough.contractId;
				const proposalMessage = JSON.stringify({
					proposal_open_contract: 1,
					contract_id: contractId,
					subscribe: 1,
				});
				derivWS.send(proposalMessage);
			} else if (receivedMessage.msg_type === "forget") {
				// console.log("FORGET", receivedMessage);
			} else if (receivedMessage.msg_type === "balance") {
				const balance = receivedMessage.balance.balance;
				store.getState().setBalance(Number(balance).toFixed(2));
			} else if (receivedMessage.msg_type === "proposal") {
				const data = receivedMessage.echo_req.passthrough;

				if (data && data.accumulators) {
					const contractDetails = receivedMessage.proposal.contract_details;

					const ticksStayIn = contractDetails.ticks_stayed_in;
					const barrierDistance = contractDetails.barrier_spot_distance;
					localStorage.setItem("barrierDistance", barrierDistance);
					store.getState().setTickCount(ticksStayIn[0]);
					const subscriptionId = receivedMessage.proposal.id;
					store.getState().setProposalId(subscriptionId);

					setTimeout(() => {
						const highBarrier = contractDetails.high_barrier;
						const lowBarrier = contractDetails.low_barrier;
						drawAccumLines(highBarrier, lowBarrier, barrierDistance);
					}, 1300);
				}
			}
		} catch (error) {
			console.log("DERIVWS ERROR", error);
		}
	});
};

const startHeartbeat = () => {
	timeInterval = setInterval(() => {
		if (derivWS.readyState === 1) {
			derivWS.send(JSON.stringify({ time: 1 }));
		}
	}, 60000);
};

export function getPeriod(resolution) {
	const resolutions = ["1", "2", "3", "5", "15", "30", "60", "120", "240", "1D"];
	const resolutionValues = [60, 120, 180, 300, 900, 1800, 3600, 7200, 14400, 86400];
	const index = resolutions.indexOf(resolution);
	if (index === -1) {
		return 60; // Default value
	}
	return resolutionValues[index];
}

window.setSymbolStorage = async (symbolItem) => {
	try {
		const symbol = symbolItem.status === "open" ? symbolItem.underlying : symbolItem.symbol;
		const symbolContracts = await getSymbolContracts(symbol);
		if (!symbolContracts) return;
		const chartType = localStorage.getItem("chartType");
		let baseSymbolInfo = {};

		baseSymbolInfo = {
			type: symbolItem.type || symbolItem.market,
			displayName: symbolItem.ticker || symbolItem.display_name,
			symbol: symbolItem.symbol || symbolItem.underlying,
			pip: symbolItem.pip || 0.01, // || tickSize,
			exchangeOpen: symbolItem.exchangeOpen || 1,
			submarket: symbolItem.submarket || "Deriv",
			ticker: symbolItem.ticker || symbolItem.display_name,
			exchange: "MIT",
			contractSize: symbolItem.contractSize || "0.1",
		};
		if (chartType === "Multiplicadores") {
			const multipliers = symbolContracts.find((contract) => contract.contract_category === "multiplier");

			if (!multipliers) {
				toast.dark("Este símbolo, No opera multiplicadores", { pauseOnHover: false });
				return;
			}

			if (multipliers.multiplier_range) {
				const mult = multipliers.multiplier_range;
				const baseValue = mult[0] / 100;
				const multiplierCount = mult.length;

				baseSymbolInfo = {
					...baseSymbolInfo,
					derivMultiplier: multiplierCount >= 1 ? mult[0] : null,
					derivMultiplier2: multiplierCount >= 2 ? mult[1] : null,
					derivMultiplier3: multiplierCount >= 3 ? mult[2] : null,
					derivMultiplier4: multiplierCount >= 4 ? mult[3] : null,
					derivMultiplier5: multiplierCount >= 5 ? mult[4] : null,
					multiplier1: multiplierCount >= 1 ? 1 : null,
					multiplier2: multiplierCount >= 2 ? mult[1] / mult[0] : null,
					multiplier3: multiplierCount >= 3 ? mult[2] / mult[0] : null,
					multiplier4: multiplierCount >= 4 ? mult[3] / mult[0] : null,
					multiplier5: multiplierCount >= 5 ? mult[4] / mult[0] : null,
				};
			}
			await localStorage.setItem("symbolInfo", JSON.stringify(baseSymbolInfo));
			await store.getState().setSymbolInfo(baseSymbolInfo);
			await window.callProposal(1, baseSymbolInfo.symbol, baseSymbolInfo.derivMultiplier);
			window.getLines();
			return;
		}
		localStorage.setItem("symbolInfo", JSON.stringify(baseSymbolInfo));
		store.getState().setSymbolInfo(baseSymbolInfo);
		window.getLines();
	} catch (error) {
		console.log("ERROR SETSYMBOLSTORAGE", error);
	}
};

export function waitForMessageOfType(messageType) {
	return new Promise((resolve, reject) => {
		const messageHandler = (event) => {
			const receivedMessage = JSON.parse(event.data);
			try {
				if (receivedMessage.msg_type === messageType) {
					derivWS.removeEventListener("message", messageHandler);
					resolve(receivedMessage);
				}
			} catch (error) {
				reject(error);
			}
		};
		derivWS.addEventListener("message", messageHandler);
	});
}

let cachedSymbols = {
	Multiplicadores: null,
	Opciones: null,
};

async function processHistoryQueue() {
	if (isProcessingQueue || historyRequestQueue.length === 0) {
		return;
	}

	isProcessingQueue = true;
	const { derivData, resolve, reject, reconnect, retries = 0 } = historyRequestQueue.shift();

	const currentTime = Date.now();
	const timeSinceLastRequest = currentTime - lastRequestTime;

	if (timeSinceLastRequest < MIN_REQUEST_INTERVAL) {
		const delay = MIN_REQUEST_INTERVAL - timeSinceLastRequest;
		await new Promise((resolve) => setTimeout(resolve, delay));
	}

	try {
		const candles = await fetchHistoryData(derivData, reconnect);
		resolve(candles);
		lastRequestTime = Date.now(); // Update last request time on success
	} catch (error) {
		if (retries < MAX_RETRIES) {
			const retryDelay = RETRY_DELAY_BASE * Math.pow(2, retries);
			console.log(`Retrying request for ${derivData.symbol} in ${retryDelay}ms (attempt ${retries + 1})`);
			await new Promise((resolve) => setTimeout(resolve, retryDelay));
			historyRequestQueue.unshift({ derivData, resolve, reject, reconnect, retries: retries + 1 }); // Add back to queue with retry count
		} else {
			console.error(`Max retries reached for ${derivData.symbol}. Request failed.`);
			reject(error);
		}
	} finally {
		isProcessingQueue = false;
		processHistoryQueue(); // Process next item in queue
	}
}

async function fetchHistoryData(derivData, reconnect) {}

export async function getHistoryDWS(derivData, reconnect) {
	// 1) Validar si hicimos la misma petición
	const lastRequest = localStorage.getItem("lastHistoryRequest");
	if (lastRequest && lastRequest !== "undefined") {
		const previousData = JSON.parse(lastRequest);
		if (previousData && derivData) {
			// Si es exactamente igual, salimos
			if (JSON.stringify(derivData) === JSON.stringify(previousData)) {
				console.log("2ND HISTORY REQUEST -> same request, skipping");
				return;
			}
			// Si solo cambió el period => handle logic?
			if (previousData.symbol === derivData.symbol && previousData.period !== derivData.period) {
				// Podrías limpiar algo o no
			}
		}
	}
	// Guardamos la nueva petición
	localStorage.setItem("lastHistoryRequest", JSON.stringify(derivData));

	// 2) Ajuste de UI (si usas TradingView):
	//    Si period anterior era "1T", se setea un tipo de gráfico, etc.
	const tvWidget = store.getState().chart;
	if (oldPeriod === "1T") {
		tvWidget.activeChart().setChartType(1); // 1 => bars, 3 => line, etc.
	}
	if (!derivData.firstTime && derivData.period === "1T") {
		tvWidget.activeChart().setChartType(3);
		oldPeriod = "1T";
	}

	// 3) Determinar granularity
	const periodSeconds = getPeriod(derivData.period);

	// 4) Convertir from/to a epoch (segundos) SOLO UNA VEZ:
	let fromSec = 0;
	let toSec = 0;

	if (typeof derivData.from === "number") {
		fromSec = derivData.from; // Asumes que ya viene en s
	} else {
		fromSec = Math.floor(new Date(derivData.from).getTime() / 1000);
	}

	if (typeof derivData.to === "number") {
		toSec = derivData.to;
	} else {
		toSec = Math.floor(new Date(derivData.to).getTime() / 1000);
	}

	// Asegurar que fromSec <= toSec
	if (fromSec > toSec) {
		const temp = fromSec;
		fromSec = toSec;
		toSec = temp;
	}

	// 5) Armar array global para TODOS los datos
	let totalCandles = [];
	const maxCount = 5000;

	// Si es la primera vez, pides la data base ("latest"), etc.
	if (derivData.firstTime) {
		// Modo ticks
		if (derivData.period === "1T") {
			const msg = {
				ticks_history: derivData.symbol,
				adjust_start_time: 1,
				count: 3000,
				end: "latest",
				style: "ticks",
				subscribe: 1,
			};
			derivWS.send(JSON.stringify(msg));
			const tickData = await waitForMessageOfType("history");
			if (tickData && tickData.history) {
				const { prices, times } = tickData.history;
				totalCandles = prices.map((price, i) => ({
					close: price,
					epoch: times[i],
				}));
			}
		} else {
			// Modo candles
			const msg = {
				ticks_history: derivData.symbol,
				adjust_start_time: 1,
				count: 2002,
				end: "latest",
				style: "candles",
				granularity: periodSeconds,
			};
			derivWS.send(JSON.stringify(msg));
			const candleData = await waitForMessageOfType("candles");
			if (candleData && candleData.candles) {
				totalCandles.push(...candleData.candles);
			}
		}

		// Ejemplo: pedir allLines
		const selectedAccount = JSON.parse(localStorage.getItem("selectedAccount"));
		socket.emit("allLines", {
			symbol: derivData.symbol,
			userId: selectedAccount.userId,
			account: selectedAccount.account,
		});
	} else {
		// 6) Descarga parcial: fromSec -> toSec
		if (derivData.period === "1T") {
			// Ticks
			// Asegúrate de no pedir + de lo necesario
			const msg = {
				ticks_history: derivData.symbol,
				adjust_start_time: 1,
				start: fromSec,
				end: toSec,
				style: "ticks",
			};
			derivWS.send(JSON.stringify(msg));
			const tickRes = await waitForMessageOfType("history");
			if (tickRes && tickRes.history) {
				const { prices, times } = tickRes.history;
				totalCandles = prices.map((price, i) => ({
					close: price,
					epoch: times[i],
				}));
			}
		} else {
			// Candles con chunking
			let start = fromSec;
			const end = toSec;

			while (start < end) {
				const nextEnd = Math.min(start + periodSeconds * maxCount, end);
				toast.dark(`Descargando: ${new Date(nextEnd * 1000).toLocaleDateString()}`, {
					position: "bottom-center",
					autoClose: 1000,
					pauseOnHover: false,
				});

				const msg = {
					ticks_history: derivData.symbol,
					adjust_start_time: 1,
					start,
					end: nextEnd,
					style: "candles",
					granularity: periodSeconds,
				};
				if (reconnect) {
					msg.subscribe = 1;
				}

				derivWS.send(JSON.stringify(msg));
				const chunk = await waitForMessageOfType("candles");
				if (chunk && chunk.candles) {
					// Concat
					totalCandles.push(...chunk.candles);

					if (reconnect && chunk.subscription) {
						const subscriptionKey = `${derivData.symbol}-${activeSymbol.period}`;
						const subscriptionItem = subscriptions.get(subscriptionKey);
						if (subscriptionItem) {
							subscriptions.set(subscriptionKey, {
								...subscriptionItem,
								subscriptionId: chunk.subscription.id,
							});
							console.log(`Updated subscription ID for ${subscriptionKey}: ${chunk.subscription.id}`);
						}
					}
				}
				// Avanzamos start, evitando solapamiento
				start = nextEnd + periodSeconds;
			}
		}
	}

	// 7) Almacenar period usado
	oldPeriod = derivData.period;

	// 8) Opcional: filtrar duplicados
	//    Map con epoch como key
	const uniqueMap = new Map();
	for (const c of totalCandles) {
		uniqueMap.set(c.epoch, c);
	}
	totalCandles = [...uniqueMap.values()];

	// 9) Retornar en el rango [fromSec, toSec] exacto
	//    (En caso de que Deriv devuelva algo extra)
	const finalCandles = totalCandles.filter((c) => c.epoch >= fromSec && c.epoch <= toSec);

	return finalCandles;
}

export function getSymbols(contractCategory) {
	const chartType = localStorage.getItem("chartType");
	// if (cachedSymbols[chartType]) {
	// 	return Promise.resolve(cachedSymbols[chartType]);
	// }

	return new Promise(async (resolve, reject) => {
		try {
			const waitConnection = (socket, timeout = 1000) => {
				return new Promise((resolve, reject) => {
					const maxAttempts = 5;
					let attempts = 0;

					const checkConnection = () => {
						attempts++;
						if (socket.readyState === WebSocket.OPEN) {
							resolve();
						} else if (attempts >= maxAttempts) {
							toast.dark(`Se perdió la conexión con Deriv`, {
								position: "bottom-center",
								autoClose: 1000,
								pauseOnHover: false,
							});
							reject(new Error("Max connection attempts reached"));
						} else {
							setTimeout(checkConnection, timeout);
						}
					};
					checkConnection();
				});
			};

			await waitConnection(derivWS);

			const contractTypes = {
				Multiplicadores: ["MULTDOWN", "MULTUP"],
				Opciones: ["CALL", "PUT"],
			};

			derivWS.send(
				JSON.stringify({
					active_symbols: "brief",
					product_type: "basic",
					// ...(chartType && { contract_type: contractTypes[chartType] }),
				})
			);

			let activeSymbols = await waitForMessageOfType("active_symbols");
			// console.log("ACTIVE SYMBOLS", activeSymbols);

			if (activeSymbols) {
				activeSymbols = activeSymbols.active_symbols;
				const completeSymbols = activeSymbols
					.filter((symbol) => symbol.market === "synthetic_index")
					.map((symbol) => ({
						type: symbol.market,
						displayName: symbol.display_name,
						symbol: symbol.symbol,
						pip: symbol.pip,
						exchangeOpen: symbol.exchange_is_open,
						submarket: symbol.submarket,
						ticker: symbol.display_name,
						exchange: "MIT",
						contractSize: symbol.market === "forex" ? 100000 : 1,
					}));

				// cachedSymbols[chartType] = completeSymbols;

				store.getState().setSymbols(completeSymbols);
				resolve(completeSymbols);
			}
		} catch (err) {
			reject(err);
		}
	});
}

export function subscribeOnStream(symbolInfo, resolution, onRealtimeCallback, subscriberUID, onResetCacheNeededCallback) {
	var period = getPeriod(resolution);
	activeSymbol.symbol = symbolInfo.ticker;
	activeSymbol.period = period;
	const subscriptionKey = `${symbolInfo.ticker}-${period}`;
	const subscriptionItem = {
		symbol: symbolInfo.ticker,
		period: period,
		onRealtimeCallback: onRealtimeCallback,
		subscriptionId: "",
	};

	subscriptions.set(subscriptionKey, subscriptionItem);
	const message = JSON.stringify({
		ticks_history: symbolInfo.ticker,
		adjust_start_time: 1,
		count: 2002,
		end: "latest",
		style: "candles",
		granularity: period,
		subscribe: 1,
	});
	derivWS.send(message);

	waitForMessageOfType("candles")
		.then((response) => {
			if (response && response.subscription) {
				const subscriptionId = response.subscription.id;

				const updatedSubscriptionItem = {
					...subscriptionItem,
					subscriptionId: subscriptionId,
				};

				subscriptions.set(subscriptionKey, updatedSubscriptionItem);
			} else if (response && response.history) {
				const subscriptionId = response.subscription.id;
				const updatedSubscriptionItem = {
					...subscriptionItem,
					subscriptionId: subscriptionId,
				};
				subscriptions.set(subscriptionKey, updatedSubscriptionItem);
			}
		})
		.catch((error) => {
			console.error("Error getting subscription ID:", error);
		});
}

export function unsubscribeFromStream() {
	for (const [subscriptionKey, subscriptionItem] of subscriptions) {
		if (subscriptionItem.symbol !== activeSymbol.symbol) {
			derivWS.send(
				JSON.stringify({
					forget: subscriptionItem.subscriptionId,
				})
			);
			subscriptions.delete(subscriptionKey);
		}
	}
}

export async function getLastCloses(granularity, count, from = null, to = null) {
	store.getState().setLoading(true);
	try {
		let allCandles = [];
		const maxCount = 5000; // Restricción del servidor
		let remainingCount = count;
		let lastEndTime = "latest";

		if (from && to) {
			// Si se proporcionan `from` y `to`, dividir el rango de tiempo
			let start = Math.round(new Date(from).getTime() / 1000);
			let end = Math.round(new Date(to).getTime() / 1000);

			if (granularity === "1T") {
				// Manejar ticks
				const message = JSON.stringify({
					ticks_history: activeSymbol.symbol,
					adjust_start_time: 1,
					start: start,
					end: end,
					style: "ticks",
				});

				derivWS.send(message);
				const ticks = await waitForMessageOfType("history");
				const history = ticks.history;
				allCandles = [...history.prices, ...allCandles];
			} else {
				// Dividir rangos en solicitudes de hasta 5000 velas
				while (start < end) {
					const nextEnd = Math.min(start + granularity * maxCount, end);
					toast.dark(`Descargando: ${new Date(nextEnd * 1000).toLocaleDateString()}`, {
						position: "bottom-center",
						autoClose: 1000,
						pauseOnHover: false,
					});
					const message = JSON.stringify({
						ticks_history: activeSymbol.symbol,
						adjust_start_time: 1,
						start: start,
						end: nextEnd,
						style: "candles",
						granularity: granularity,
					});

					derivWS.send(message);
					const response = await waitForMessageOfType("candles");
					if (!response || !response.candles) break;

					const newCandles = response.candles.map((candle) => ({
						close: candle.close,
						high: candle.high,
						low: candle.low,
						open: candle.open,
						time: candle.epoch * 1000,
					}));

					allCandles = [...allCandles, ...newCandles];
					start = nextEnd + granularity; // Mover el rango hacia adelante
				}
			}
		} else {
			// Si no se proporcionan `from` ni `to`, usar el conteo máximo
			while (remainingCount > 0) {
				const batchCount = Math.min(remainingCount, maxCount);

				if (granularity === "1T") {
					const message = JSON.stringify({
						ticks_history: activeSymbol.symbol,
						adjust_start_time: 1,
						count: batchCount,
						end: lastEndTime,
						style: "ticks",
					});

					derivWS.send(message);
					const ticks = await waitForMessageOfType("history");
					const history = ticks.history;
					allCandles = [...history.prices, ...allCandles];

					if (allCandles.length > 0) {
						lastEndTime = history.times[0];
					}
				} else {
					const message = JSON.stringify({
						ticks_history: activeSymbol.symbol,
						adjust_start_time: 1,
						count: batchCount,
						end: lastEndTime,
						style: "candles",
						granularity: granularity,
					});

					derivWS.send(message);
					const response = await waitForMessageOfType("candles");
					if (!response || !response.candles) break;

					const newCandles = response.candles.map((candle) => ({
						close: candle.close,
						high: candle.high,
						low: candle.low,
						open: candle.open,
						time: candle.epoch * 1000,
					}));

					allCandles = [...newCandles, ...allCandles];

					if (response.candles.length > 0) {
						lastEndTime = response.candles[0].epoch;
					}
				}
				remainingCount -= batchCount;
			}
		}
		store.getState().setLoading(false);
		return allCandles;
	} catch (error) {
		console.error(error.message);
		return [];
	}
}

export async function getShocks() {
	const proposalId = store.getState().proposalId;
	if (proposalId !== null) {
		derivWS.send(JSON.stringify({ forget: proposalId }));
		store.getState().setProposalId(null);
		await new Promise((resolve) => setTimeout(resolve, 250));
		return getShocks();
	}

	try {
		const [riskAmount, rate, symbolInfo] = await Promise.all([
			localStorage.getItem("riskAmount"),
			localStorage.getItem("sliderValue"),
			store.getState().symbolInfo,
		]);

		const callMessage = JSON.stringify({
			proposal: 1,
			amount: riskAmount,
			growth_rate: rate / 100,
			basis: "stake",
			contract_type: "ACCU",
			currency: "USD",
			symbol: symbolInfo.symbol,
			subscribe: 1,
			passthrough: { accumulators: true },
		});
		console.log(" GET SHOCKS", callMessage);
		await derivWS.send(callMessage);
		const callProposal = await waitForMessageOfType("proposal");
		return callProposal.proposal.contract_details.ticks_stayed_in;
	} catch (error) {
		console.error(error.message);
		return [];
	}
}

export async function calcBarrier(distance, amount, symbol, slider) {
	const sendMessage = JSON.stringify({
		proposal: 1,
		amount: Number(amount),
		barrier: distance,
		basis: "payout",
		contract_type: "CALL",
		currency: "USD",
		duration: Number(slider),
		duration_unit: "s",
		symbol: symbol,
	});
	derivWS.send(sendMessage);
	const proposal = await waitForMessageOfType("proposal");
	console.log("PROPOSAL", proposal);
	if (proposal.error) {
		return proposal.error;
	} else {
		return proposal.proposal;
	}
}

export function handleOpenOption(option) {
	if (!hasCalledDerivWS) {
		const selectedAccount = JSON.parse(localStorage.getItem("selectedAccount"));
		const authMessage = JSON.stringify({ authorize: selectedAccount.token, passthrough: { contractId: option.contractId } });
		derivWS.send(authMessage);
		hasCalledDerivWS = true;
	}
}

const drawAccumLines = async (highPrice, lowPrice, barrierDistance) => {
	const optionType = await localStorage.getItem("optionType");

	if (optionType === "accumulators") {
		const tvWidget = store.getState().chart;

		if (tvWidget) {
			if (accumHighLine) {
				accumHighLine.setPrice(highPrice).setText(`Barrera`).setQuantity(barrierDistance).setExtendLeft(false);
			} else {
				accumHighLine = tvWidget
					.chart()
					.createOrderLine()
					.setText(`High`)
					.setQuantity(barrierDistance)
					.setPrice(highPrice)
					.setLineColor("#4CAF50")
					.setBodyTextColor("#4CAF50")
					.setBodyBackgroundColor("rgba(0, 0, 0, 0)")
					.setQuantityBackgroundColor("rgba(0, 0, 0, 0)")
					.setExtendLeft(false)
					.setLineLength(30, "percentage");
			}

			if (accumLowLine) {
				accumLowLine.setPrice(lowPrice).setText(`Barrera`).setQuantity(barrierDistance).setExtendLeft(false);
			} else {
				accumLowLine = tvWidget
					.chart()
					.createOrderLine()
					.setText(`Low`)
					.setQuantity(barrierDistance)
					.setPrice(lowPrice)
					.setLineColor("#4CAF50")
					.setBodyTextColor("#4CAF50")
					.setBodyBackgroundColor("rgba(0, 0, 0, 0)")
					.setQuantityBackgroundColor("rgba(0, 0, 0, 0)")
					.setExtendLeft(false)
					.setLineLength(30, "percentage");
			}
		}
	}
};

export const removeAccumLines = () => {
	const tvWidget = store.getState().chart;

	if (tvWidget) {
		if (accumHighLine) {
			accumHighLine.remove();
			accumHighLine = null;
		}
		if (accumLowLine) {
			accumLowLine.remove();
			accumLowLine = null;
		}
	}
};

export const getSymbolContracts = async (symbol) => {
	const contractsMessage = JSON.stringify({
		contracts_for: symbol,
		currency: "USD",
		landing_company: "svg",
		product_type: "basic",
	});

	derivWS.send(contractsMessage);
	const contracts = await waitForMessageOfType("contracts_for");
	if (contracts.error) {
		return null;
	}
	const for1 = contracts.contracts_for;
	return for1.available;
};

window.changeSymbol = async (symbol) => {
	const tvWidget = store.getState().chart;

	if (tvWidget && tvWidget.chart) {
		tvWidget.chart().setSymbol(symbol, () => {
			tvWidget.activeChart().resetData();
		});
	}
};

window.callProposal = async (amount, symbol, multiplier) => {
	if (!amount || !symbol || !multiplier) return;
	try {
		// Wait for WebSocket to be ready
		while (derivWS.readyState !== WebSocket.OPEN) {
			await new Promise((resolve) => setTimeout(resolve, 100));
		}

		let sInfo = await JSON.parse(localStorage.getItem("symbolInfo"));
		amount = Number(amount) < 1 ? 1 : Number(amount) > 2000 ? 2000 : Number(amount).toFixed(2);

		const sendMessage = JSON.stringify({
			proposal: 1,
			amount: amount,
			basis: "stake",
			contract_type: "MULTUP",
			currency: "USD",
			symbol: symbol,
			multiplier: multiplier,
		});

		derivWS.send(sendMessage);
		const derivProposal = await waitForMessageOfType("proposal");

		if (derivProposal.error && derivProposal.error.code === "ContractBuyValidationError") {
			toast.dark(symbol + " Temporalmente no disponible", {
				position: "top-right",
				autoClose: 2000,
				hideProgressBar: true,
				closeOnClick: false,
				pauseOnHover: false,
				draggable: false,
				progress: undefined,
			});
			return null;
		}

		const proposal = derivProposal.proposal;

		const limitOrder = proposal.limit_order;
		const commission = proposal.commission;
		store.getState().setCommission(commission);
		const stopOut = limitOrder.stop_out.value;
		const spot = proposal.spot;
		const propMultiplier = proposal.multiplier;

		if (Number(propMultiplier) === Number(sInfo.derivMultiplier)) {
			const stopOutToSt = Number(spot) - Number(stopOut);
			sInfo.stopOut = stopOutToSt;
			sInfo.commission = commission;
			localStorage.setItem("symbolInfo", JSON.stringify(sInfo));
			await store.getState().setSymbolInfo(sInfo);
		}

		return proposal.error ? proposal.error : proposal;
	} catch (error) {
		console.log("ERROR CALL PROPOSAL", error);
	}
};

window.getLines = async () => {
	const selectedAccount = JSON.parse(localStorage.getItem("selectedAccount"));

	let allPos = {
		userId: selectedAccount.userId,
		account: selectedAccount.account,
	};
	await socket.emit("allLines", allPos);
};

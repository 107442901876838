import React, { useState, useEffect, lazy, startTransition, Suspense } from "react";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import LoginPage from "./components/TVChartContainer/pages/Login.jsx";
import RegisterPage from "./components/TVChartContainer/pages/Register.jsx";
import LottieLoader from "./lottie/lottie.jsx";

// Lazy load del TVChartContainer
const TVChartContainer = lazy(() =>
	import("./components/TVChartContainer/index").then((module) => ({
		default: module.default || module.TVChartContainer,
	}))
);

const App = () => {
	const [isAuthenticated, setAuthenticated] = useState(false);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const token = localStorage.getItem("token");
		if (token) {
			setAuthenticated(true);
		}
		setLoading(false);
	}, []);

	if (loading) {
		return (
			<div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
				<LottieLoader />
			</div>
		);
	}

	return (
		<Router>
			<ToastContainer />
			<Switch>
				<Route path="/login">{isAuthenticated ? <Redirect to="/chart" /> : <LoginPage setAuthenticated={setAuthenticated} />}</Route>
				<Route path="/register">{isAuthenticated ? <Redirect to="/chart" /> : <RegisterPage setAuthenticated={setAuthenticated} />}</Route>
				<PrivateRoute path="/chart" isAuthenticated={isAuthenticated}>
					<Suspense fallback={<LoadingScreen />}>
						<ChartWithLoader />
					</Suspense>
				</PrivateRoute>
				<Redirect from="/" to={isAuthenticated ? "/chart" : "/login"} />
			</Switch>
		</Router>
	);
};

const ChartWithLoader = () => {
	const [chartLoading, setChartLoading] = useState(true);

	useEffect(() => {
		const timer = setTimeout(() => {
			startTransition(() => {
				setChartLoading(false);
			});
		}, 3000);

		return () => clearTimeout(timer);
	}, []);

	return (
		<div style={{ position: "relative", height: "100%", width: "100%" }}>
			{chartLoading && (
				<div
					style={{
						position: "absolute",
						top: 0,
						left: 0,
						right: 0,
						bottom: 0,
						backgroundColor: "rgba(0, 0, 0, 0.5)",
						zIndex: 1000,
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
					}}>
					<LottieLoader />
				</div>
			)}
			<TVChartContainer />
		</div>
	);
};

const LoadingScreen = () => (
	<div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
		<LottieLoader />
	</div>
);

const PrivateRoute = ({ children, isAuthenticated, ...rest }) => (
	<Route
		{...rest}
		render={({ location }) =>
			isAuthenticated ? (
				children
			) : (
				<Redirect
					to={{
						pathname: "/login",
						state: { from: location },
					}}
				/>
			)
		}
	/>
);

export default App;

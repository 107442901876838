import React, { useState, useEffect, useRef, useCallback, useMemo } from "react";
import { Box, Card, TextField, Button as MUIButton, Typography, Slider } from "@mui/material";
import { io } from "socket.io-client";
import { Rnd } from "react-rnd";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { store } from "../../../../../store/store.js";
import { toast } from "react-toastify";
import socket from "../../../../../socket/Socket.js";
import { derivWS, waitForMessageOfType, handleOpenOption, getShocks, getSymbols, removeAccumLines, getLastCloses } from "../../../streaming.js";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import { useHistory } from "react-router-dom";
import { Menu, MenuItem, SubMenu } from "@spaceymonk/react-radial-menu";

const theme = createTheme({
	components: {
		MuiButton: {
			styleOverrides: {
				root: {
					minWidth: "0", // Override the default minWidth
				},
			},
		},
	},
	palette: {
		mode: "dark",
		primary: {
			main: "#01a79e",
		},
		background: {
			default: "#121212",
			paper: "#1d1d1d",
		},
		text: {
			primary: "#ffffff",
		},
	},
});

const calculatePosition = ({ x }) => {
	return {
		x: x - 175,
		y: 50,
	};
};

let activeSL = true;
let activeTP = true;
let activeSLTP = false;
let orderPosition = false;

export const translations = {
	"Error:": "Error:",
	"Barrier Result:": "Resultado de la Barrera:",
	"Barrier is out of acceptable range.": "La barrera está fuera del rango aceptable.",
	"This contract offers no return.": "Este contrato no ofrece retorno.",
	"Please enter a stake amount that's at least 0.35.": "Ingrese una cantidad de apuesta de al menos 0.35.",
	"Number of ticks must be between 5 and 10.": "El número de ticks debe estar entre 5 y 10.",
	"Input validation failed: barrier": "Validación de entrada fallida: barrera",
	"Trading is not offered for this duration.": "El comercio no está disponible para esta duración.",
	"Missing required contract parameters (growth_rate).": "Faltan parámetros, selecciona un Tick",
	"Maximum stake allowed is 1000.00.": "La apuesta máxima permitida es de 1000.00.",
};

export function MyDraggableComponent() {
	const history = useHistory();
	const chartType = localStorage.getItem("chartType");
	const tvWidget = store((state) => state.chart);
	const selectedAccount = JSON.parse(localStorage.getItem("selectedAccount"));
	const user = JSON.parse(localStorage.getItem("user"));
	const symbolInfo = store((state) => state.symbolInfo);
	const balance = store((state) => state.balance);
	const commission = store((state) => state.commission);
	const inputValue = store((state) => state.inputValue);
	const riskAmount = store((state) => state.riskAmount);
	const showPositionMenu = store((state) => state.showPositionMenu);

	const [position, setPosition] = useState(() => {
		const savedPosition = localStorage.getItem("windowPosition");
		return savedPosition ? JSON.parse(savedPosition) : calculatePosition({ x: window.innerWidth / 1.5 });
	});
	const [retCandlesQty, setRetCandlesQty] = useState(() => {
		return localStorage.getItem("retCandlesQty") || 0.1;
	});
	const [tradeAmount, setTradeAmount] = useState(1);
	const [slTpButtonColor, setSlTpButtonColor] = useState("#808080");
	const slTpButton = store((state) => state.slTpButton);
	const retCandles = store((state) => state.retCandles);
	const trailingStop = store((state) => state.trailingStop);
	const showPanel = store((state) => state.showPanel);
	const [preStopLoss, setPreStopLoss] = useState(null);
	const [positionType, setPositionType] = useState("multipliers");
	const [contractType, setContractType] = useState();
	const [sliderValue, setSliderValue] = useState(0);
	const [barrier, setBarrier] = useState("0.14");
	const [profitTick, setProfitTick] = useState(5);
	const [callPayout, setCallPayout] = useState();
	const [putPayout, setPutPayout] = useState();
	const [dragging, setDragging] = useState(false);
	const [isLocked, setIsLocked] = useState(false);
	const [customCandles, setCustomCandles] = useState();
	const [timer, setTimer] = useState(null);
	const [positionMenu, setPositionMenu] = useState({ x: 0, y: 0 });

	let lastBarRef = useRef(null);
	const trailingIntervalRef = useRef(null);
	const tradeAmountRef = useRef(null);
	const derivMultiplierRef = useRef(null);
	const multiplierRef = useRef(null);
	const newPipValueRef = useRef(null);
	const riskAmountRef = useRef(null);
	const riskAmountComissionRef = useRef(null);
	const riskAmountTotalRef = useRef(null);
	const slRetCandlesRef = useRef(null);
	const tpRetCandlesRef = useRef(null);
	const slLineRef = useRef(null);
	const tpLineRef = useRef(null);
	const trailingLineRef = useRef(null);
	const SL = useRef(null);
	const TP = useRef(null);
	const tpProfit = useRef(null);
	const slProfit = useRef(null);
	const pipsSL = useRef(null);
	const pipsTP = useRef(null);
	const slOrderLineRef = useRef(null);
	const tpOrderLineRef = useRef(null);
	const SLOrder = useRef(null);
	const TPOrder = useRef(null);
	const pipsOrderSL = useRef(null);
	const pipsOrderTP = useRef(null);
	const orderRef = useRef(null);
	const existingLinesRef = useRef({});
	const existingOrdersRef = useRef({});
	const symbolInfoRef = useRef(symbolInfo);
	const tvWidgetRef = useRef(tvWidget);
	const barrierLineRef = useRef(null);
	const barrierIntervalRef = useRef(null);
	const barrierRef = useRef(null);
	const sliderRef = useRef(null);
	const callPayoutRef = useRef(null);
	const putPayoutRef = useRef(null);
	const contractTypeRef = useRef(contractType);
	const actualBalanceRef = useRef(null);
	const calculatedValueRef = useRef(null);
	const accumBodyRef = useRef(null);
	const derivCalcTPRef = useRef(null);
	const derivCalcSLRef = useRef(null);
	const entriesRef = useRef(null);
	const allLinesRef = useRef(null);
	const modifingPosRef = useRef(null);
	const trailingStopLinesRef = useRef([]);
	const trailingStopIntervalRef = useRef(null);
	const lastTrailingStopPriceRef = useRef({});
	const trailingDistanceRef = useRef({});
	let trailingPositions = [];

	useEffect(() => {
		if (commission) {
			if (slLineRef.current) {
				const newRisk = Number(riskAmountRef.current) + Number(commission);
				slLineRef.current.setText(`SL: $${newRisk.toFixed(2)} --X: ${derivMultiplierRef.current}`);
			}
		}
	}, [commission]);

	//==============================================// INITIAL LOTS RND POSITION
	useEffect(() => {
		const serRiskStuff = async () => {
			const iv = await localStorage.getItem("inputValue");
			const rcq = await localStorage.getItem("retCandleQty");
			const rA = await localStorage.getItem("riskAmount");
			store.getState().setInputValue(iv ? Number(iv) : 1);
			store.getState().setRiskAmount(iv ? Number(rA) : 1);
			store.getState().setRetCandlesQty(rcq ? Number(rcq) : 1);
			window.calculateAmount(iv ? Number(iv) : 1);
		};
		if (balance !== null && balance > 0) {
			serRiskStuff();
		}
		const handleResize = () => {
			const newWidth = window.innerWidth;
			setPosition(calculatePosition({ x: newWidth / 2 }));
		};
		window.addEventListener("resize", handleResize);
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	//==============================================// GET LAST BAR
	const getLastBarValue = useCallback(() => {
		return store.getState().lastBar;
	}, []);

	useEffect(() => {
		if (!selectedAccount || !symbolInfo) return;
		const interval = setInterval(() => {
			const lastBarValue = getLastBarValue();
			lastBarRef.current = lastBarValue;
		}, 1000);

		return () => {
			clearInterval(interval);
		};
	}, [getLastBarValue, symbolInfo]);

	//==============================================// LOCAL STORAGE
	useEffect(() => {
		const chargeLocal = async () => {
			const barrier = await localStorage.getItem("barrier");
			const storageSLider = localStorage.getItem("sliderValue");
			const profitTick = localStorage.getItem("profitTick");

			setBarrier(barrier !== null ? barrier : "+0.150");
			setSliderValue(Number(storageSLider) || 5);
			setProfitTick(profitTick !== null ? profitTick : "5");

			barrierRef.current = barrier !== null ? barrier : "+0.150";
			sliderRef.current = storageSLider !== null ? storageSLider : "5";
		};
		chargeLocal();

		return () => {
			if (trailingLineRef.current) {
				stopTrailingStopInterval();
			}
		};
	}, []);

	//==============================================// CHART TYPE
	useEffect(() => {
		const test = async () => {
			let chartType = localStorage.getItem("chartType");
			chartType === null ? localStorage.setItem("chartType", "Multiplicadores") : chartType;

			if (chartType === "Opciones") {
				if (contractTypeRef.current === "higherLower" || contractTypeRef.current === "touchNoTouch") {
					startBarrierInterval();
				}
			}
		};
		test();

		if (chartType === "Multiplicadores") {
			stopBarrierInterval();
		}
	}, [chartType, contractTypeRef.current]);

	//==============================================// SYMBOLINFO REF - GET SHOCKS
	useEffect(() => {
		async function call() {
			if (!symbolInfo) return;
			symbolInfoRef.current = symbolInfo;
			derivMultiplierRef.current = symbolInfo.derivMultiplier;
			store.getState().setMultiplier(derivMultiplierRef.current);

			const optionType = await localStorage.getItem("optionType");
			contractTypeRef.current = optionType;
			setContractType(optionType);

			if (chartType === "Opciones" && optionType === "accumulators") {
				getShocks();
			}

			// await updateBarrier(barrierRef.current);
			// await handleContractType("riseFall");
		}
		call();
	}, [symbolInfo]);

	//==============================================// SOCKET ON
	useEffect(() => {
		const handleAllLines = async (data) => {
			// console.log("ALL LINES", data);
			allLinesRef.current = data;
			try {
				store.getState().setOpenPositions(data);
				const tvWidget = await store.getState().chart;
				let symbolInfo = await JSON.parse(localStorage.getItem("symbolInfo"));
				if (!symbolInfo || !tvWidget) {
					setTimeout(() => handleAllLines(data), 500);
					return;
				}

				Object.keys(existingLinesRef.current).forEach((key) => {
					if (existingLinesRef.current[key].remove) {
						existingLinesRef.current[key].remove();
					}
					delete existingLinesRef.current[key];
					window.removePreviewPB();
				});

				if (trailingIntervalRef.current) {
					clearInterval(trailingIntervalRef.current);
					trailingIntervalRef.current = null;
				}

				if (data.length === 0) {
					clearInterval(trailingStopIntervalRef.current);
					trailingStopIntervalRef.current = null;
					Object.keys(trailingStopLinesRef.current).forEach((contractId) => {
						trailingStopLinesRef.current[contractId].remove();
						delete trailingStopLinesRef.current[contractId];
					});
				} else {
					const currentPositions = data.map((p) => p.contractId.toString());
					Object.keys(trailingStopLinesRef.current).forEach((contractId) => {
						if (!currentPositions.includes(contractId)) {
							trailingPositions = trailingPositions.filter((p) => Number(p.contractId) !== Number(contractId));
							if (trailingStopLinesRef.current[contractId]) {
								trailingStopLinesRef.current[contractId].remove();
								delete trailingStopLinesRef.current[contractId];
							}

							if (trailingPositions.length === 0 && trailingStopIntervalRef.current) {
								clearInterval(trailingStopIntervalRef.current);
								trailingStopIntervalRef.current = null;
							}
						} else {
							clearInterval(trailingStopIntervalRef.current);
							trailingStopIntervalRef.current = null;
						}
					});

					if (Object.keys(trailingStopLinesRef.current).length === 0) {
						clearInterval(trailingStopIntervalRef.current);
						trailingStopIntervalRef.current = null;
					}
				}
				if (data === null) return;

				data.forEach((position) => {
					if (position.symbol === symbolInfo.symbol) {
						const positionKey = `position_${position.contractId}`;
						const linePbKey = `linePb${position.contractId}`;

						if (position.linePB) {
							existingLinesRef.current[linePbKey] = tvWidget.chart().createShape(
								{ time: tvWidget.activeChart().getVisibleRange().from, price: position.price },
								{
									shape: "horizontal_line",
									overrides: {
										linecolor: position.contractType === "MULTUP" ? "#01a79e" : "#cc2e3d",
										linewidth: 0.5,
										linestyle: 2,
										showLabel: true,
										title: `linePb${position.position}`,
										text: `< < POSITION ${position.position} - TRADE ${position.tradeAmount} - X ${position.derivMultiplier} > >`,
										textcolor: position.contractType === "MULTUP" ? "#01a79e" : "#cc2e3d",
										fontsize: 11,
										fontFamily: "'Orbitron', sans-serif",
										zOrder: "top",
										lineLeap: 2,
									},
								}
							);
						} else {
							existingLinesRef.current[positionKey] = tvWidget
								.chart()
								.createPositionLine()
								.setText(position.orderPosition ? `${position.type}` : `${position.type}  X: ${position.derivMultiplier}`)
								.setQuantity(Number(position.tradeAmount).toFixed(2))
								.setPrice(position.orderPosition ? Number(position.orderPrice) : Number(position.entryPrice))
								.setLineColor(position.orderPosition ? "orange" : "#4599d9")
								.setQuantityBorderColor(position.orderPosition ? "orange" : "#4599d9")
								.setBodyTextColor("#a9a9a9")
								.setQuantityTextColor("#a9a9a9")
								.setBodyBackgroundColor("rgba(0, 0, 0, 0)")
								.setQuantityBackgroundColor("rgba(0, 0, 0, 0)")
								.setCloseButtonBackgroundColor("rgba(0, 0, 0, 0)")
								.setCloseButtonIconColor("#a9a9a9")
								.setCloseButtonBorderColor(position.orderPosition ? "orange" : "#4599d9")
								.setBodyBorderColor(position.orderPosition ? "orange" : "#4599d9")
								.setTooltip(position.contractId)
								.setProtectTooltip(position.type.startsWith("Orden") ? "Orden" : "Activar SL/TP")
								.onClose("onClose called", function (text) {
									socket.emit("sellContract", position);
								})
								.onModify(function (e) {
									if (position.type.startsWith("Orden")) {
										console.log("NO ES ORDEN");
										return;
									}
									const coords = store.getState().coords;
									setPositionMenu(coords);
									store.getState().setShowPositionMenu(!showPositionMenu);
									modifingPosRef.current = position;
								});

							if (position.stopLoss !== null) {
								window.createSLTPLine(Number(position.contractId), "stopLoss", position);
							}
							if (position.takeProfit !== null) {
								window.createSLTPLine(Number(position.contractId), "takeProfit", position);
							}

							if (position.trailingStop) {
								startTrailingStopInterval0(position);
							}
						}
					}
				});
			} catch (error) {
				console.log("ERROR ALL LINES", error);
			}
		};

		window.createSLTPLine = (contractId, type, position) => {
			const slKey = `stopLoss_${contractId}`;
			const tpKey = `takeProfit_${contractId}`;
			const line = tvWidgetRef.current.chart().createOrderLine();

			if (type === "stopLoss") {
				let realSL = (position.commission + Number(position.totalRisk)).toFixed(2);
				if (position.orderPosition) {
					realSL = (position.commission + Number(position.slProfit)).toFixed(2);
				}
				existingLinesRef.current[slKey] = line
					.setText(
						position.orderPosition ? `Order SL: $${realSL} -- Pips: ${position.pipsSL}` : `SL: $${realSL} -- Pips: ${position.pipsSL}`
					)
					.setPrice(
						position.stopLoss
							? position.stopLoss
							: position.type === "Buy"
							? position.entryPrice - 10 * position.pip
							: position.entryPrice + 10 * position.pip
					)
					.setQuantity(Number(position.tradeAmount).toFixed(2))
					.setLineColor("#cc2e3d")
					.setQuantityBorderColor("#cc2e3d")
					.setBodyTextColor("#cc2e3d")
					.setBodyBorderColor("#cc2e3d")
					.setBodyBackgroundColor("rgba(0, 0, 0, 0)")
					.setQuantityBackgroundColor("rgba(0, 0, 0, 0)")
					.setCancelButtonBackgroundColor("rgba(0, 0, 0, 0)")
					.setCancelButtonIconColor("#cc2e3d")
					.setCancelButtonBorderColor("#cc2e3d")
					.setTooltip(contractId)
					.onMove(async function () {
						if (Number(this.getTooltip()) === Number(position.contractId)) {
							existingLinesRef.current[`position_${contractId}`].setQuantityBackgroundColor("rgba(0, 0, 0, 0)");
							const selectedAccount = JSON.parse(localStorage.getItem("selectedAccount"));
							const currentPrice = this.getPrice();
							const entryPrice = position.orderPosition ? Number(position.orderPrice) : Number(position.entryPrice);
							const tradeAmount = Number(position.tradeAmount);
							let pipValue = Number(position.pipValue);

							const newRisk = await calculateMultiplierProfit(entryPrice, currentPrice, tradeAmount, position.derivMultiplier);
							const totalNewRisk = Number(newRisk.profit) + Number(position.commission) + Number(position.commission);
							const pips = Number(newRisk.pips);
							// this.setQuantity(totalNewRisk);

							if (position.preStopLoss) {
								pipValue = pipValue * 2;
							}

							if (position.type === "Buy" && currentPrice > Number(lastBarRef.current.close)) {
								toast.error("Stop Loss no puede ser mayor al precio");
								return;
							} else if (position.type === "Sell" && currentPrice < Number(lastBarRef.current.close)) {
								toast.error("Stop Loss no puede ser menor al precio");
								return;
							}

							const SLPrice = this.getPrice();
							const pipsSL = Math.abs(SLPrice - entryPrice);
							slProfit.current = pipsSL * pipValue * tradeAmount;

							// let realSL = (position.commission + Number(position.totalRisk)).toFixed(2);
							// if (position.orderPosition) {
							// 	realSL = (position.commission + Number(position.slProfit)).toFixed(2);
							// }
							// this.setText(`SL: $${realSL} -- Pips: ${pipsSL.toFixed(2)}`);
							updateStopLoss(contractId, this.getPrice(), selectedAccount, pips, totalNewRisk);

							existingLinesRef.current[slKey].remove();
							delete existingLinesRef.current[slKey];
						}
					})
					.onCancel("onClose called", function (text) {
						if (Number(this.getTooltip()) === Number(contractId)) {
							const selectedAccount = JSON.parse(localStorage.getItem("selectedAccount"));
							removeStopLoss(contractId, selectedAccount);
						}
					});
			} else if (type === "takeProfit") {
				const realTP = Math.abs(Number(position.tpProfit) - position.commission).toFixed(2);
				existingLinesRef.current[tpKey] = line
					.setText(
						position.orderPosition
							? `Orden TP: $${position.tpProfit} ------ Pips: ${position.pipsTP}`
							: position.bot === "manual"
							? `TP: $${realTP} -- Pips: ${position.pipsTP} -- Payout: ${position.payout}`
							: `BOT TakeProfit By Time`
						// `TP: $${position.tpProfit} ------ Pips: ${position.pipsTP}`
					)
					.setPrice(
						position.takeProfit
							? position.takeProfit
							: position.type === "Buy"
							? position.entryPrice + 10 * position.pip
							: position.entryPrice - 10 * position.pip
					)
					.setLineColor("#01a79e")
					.setQuantity(Number(position.tradeAmount).toFixed(2))
					.setQuantityBorderColor("#01a79e")
					.setBodyTextColor("#01a79e")
					.setQuantityTextColor("#a9a9a9")
					.setBodyBorderColor("#01a79e")
					.setBodyBackgroundColor("rgba(0, 0, 0, 0)")
					.setQuantityBackgroundColor("rgba(0, 0, 0, 0)")
					.setCancelButtonBackgroundColor("rgba(0, 0, 0, 0)")
					.setCancelButtonIconColor("#01a79e")
					.setCancelButtonBorderColor("#01a79e")
					.setTooltip(contractId)
					.onMove(async function () {
						if (Number(this.getTooltip()) === contractId) {
							existingLinesRef.current[`position_${contractId}`].setQuantityBackgroundColor("rgba(0, 0, 0, 0)");
							const selectedAccount = JSON.parse(localStorage.getItem("selectedAccount"));

							const currentPrice = this.getPrice();
							const entryPrice = position.orderPosition ? Number(position.orderPrice) : Number(position.entryPrice);
							const tradeAmount = Number(position.tradeAmount);
							const derivMultiplier = Number(position.derivMultiplier);

							if (position.type === "Buy" && currentPrice < Number(lastBarRef.current.close)) {
								toast.error("Take Profit no puede ser menor al precio de entrada");
								return;
							} else if (position.type === "Sell" && currentPrice > Number(lastBarRef.current.close)) {
								toast.error("Take Profit no puede ser mayor al precio de entrada");
								return;
							}

							const realTP = Math.abs(Number(position.tpProfit) - position.commission);
							derivCalcTPRef.current = await calculateMultiplierProfit(entryPrice, currentPrice, tradeAmount, derivMultiplier);

							this.setText(`TP: $${realTP} -- Pips: ${derivCalcTPRef.current.pips} -- Payout: ${derivCalcTPRef.current.payout}`);

							const priceLine = Number(this.getPrice()).toFixed(2);
							const profit = Number(derivCalcTPRef.current.profit).toFixed(2);
							updateTakeProfit(contractId, priceLine, selectedAccount, derivCalcTPRef.current.pips, profit);
							// existingLinesRef.current[tpKey].remove();
							// delete existingLinesRef.current[tpKey];
						}
					})
					.onCancel("onClose called", function (text) {
						if (Number(this.getTooltip()) === contractId) {
							const selectedAccount = JSON.parse(localStorage.getItem("selectedAccount"));
							removeTakeProfit(contractId, selectedAccount);
						}
					});
			}
		};

		const handleClosePosition = (data) => {
			if (data.symbol === symbolInfoRef.current.symbol) {
				const contractId = data.contractId;

				// Eliminar la línea de posición
				const positionKey = `position_${contractId}`;
				if (existingLinesRef.current[positionKey]) {
					delete existingLinesRef.current[positionKey];
				}

				// Eliminar la línea de Stop Loss
				const slKey = `stopLoss_${contractId}`;
				if (existingLinesRef.current[slKey]) {
					delete existingLinesRef.current[slKey];
				}

				// Eliminar la línea de Take Profit
				const tpKey = `takeProfit_${contractId}`;
				if (existingLinesRef.current[tpKey]) {
					delete existingLinesRef.current[tpKey];
				}
			}
		};

		function removeStopLoss(contractId, sA) {
			socket.emit("modifyPosition", {
				account: sA.account,
				userId: sA.userId,
				contractId: contractId,
				priceSL: null,
				modify: "SL",
			});
		}

		function removeTakeProfit(contractId, sA) {
			socket.emit("modifyPosition", {
				account: sA.account,
				userId: sA.userId,
				contractId: contractId,
				priceTP: null,
				modify: "TP",
			});
		}

		const removeLine = (contractId, type) => {
			const key = `${type}_${contractId}`;
			delete existingLinesRef.current[key];
		};

		const handleAllOrder = (data) => {
			// console.log("ALL ORDERS", existingOrdersRef.current);

			if (data === null) return;

			Object.keys(existingOrdersRef.current).forEach((key) => {
				if (existingOrdersRef.current[key].remove) {
					existingOrdersRef.current[key].remove();
				}
				delete existingOrdersRef.current[key];
			});

			data.forEach((order) => {
				if (order.symbol === symbolInfoRef.current.symbol) {
					const orderKey = `order_${order.contractId}`;
					const slKey = `orderSL_${order.contractId}`;
					const tpKey = `orderTP_${order.contractId}`;

					// Crear línea de orden tvWidgetRef.current.chart().createPositionLine();
					existingOrdersRef.current[orderKey] = tvWidgetRef.current
						.chart()
						.createPositionLine()
						.setText(`Orden ${order.type}`)
						.setQuantity(Number(order.riskAmount).toFixed(2))
						.setPrice(order.orderPrice)
						.setLineColor("blue")
						.setQuantityBorderColor("blue")
						.setBodyTextColor("#a9a9a9")
						.setQuantityTextColor("#a9a9a9")
						.setBodyBackgroundColor("rgba(0, 0, 0, 0)")
						.setQuantityBackgroundColor("rgba(0, 0, 0, 0)")
						.setCloseButtonBackgroundColor("rgba(0, 0, 0, 0)")
						.setCloseButtonIconColor("#a9a9a9")
						.setCloseButtonBorderColor("blue")
						.setBodyBorderColor("blue")
						.setTooltip(order.contractId)
						.onClose("onClose called", function (text) {
							let forCancel = {
								symbol: order.symbol,
								contractId: order.contractId,
								userId: order.userId,
							};
							socket.emit("closeOrder", forCancel);
						});

					// Crear línea de Stop Loss si existe
					if (order.stopLoss) {
						existingOrdersRef.current[slKey] = tvWidgetRef.current
							.chart()
							.createOrderLine()
							.setText(`Order Stop Loss`)
							.setPrice(order.stopLoss)
							.setLineColor("orange")
							.setQuantity(Number(order.riskAmount).toFixed(2))
							.setQuantityBorderColor("orange")
							.setBodyTextColor("orange")
							.setQuantityTextColor("#a9a9a9")
							.setBodyBorderColor("orange")
							.setBodyBackgroundColor("rgba(0, 0, 0, 0)")
							.setQuantityBackgroundColor("rgba(0, 0, 0, 0)")
							.setTooltip(order.contractId);
					}

					// Crear línea de Take Profit si existe
					if (order.takeProfit) {
						existingOrdersRef.current[tpKey] = tvWidgetRef.current
							.chart()
							.createOrderLine()
							.setText(`Order Take Profit`)
							.setPrice(order.takeProfit)
							.setLineColor("#01a79e")
							.setQuantity(Number(order.riskAmount).toFixed(2))
							.setQuantityBorderColor("#01a79e")
							.setBodyTextColor("#01a79e")
							.setQuantityTextColor("#a9a9a9")
							.setBodyBorderColor("#01a79e")
							.setBodyBackgroundColor("rgba(0, 0, 0, 0)")
							.setQuantityBackgroundColor("rgba(0, 0, 0, 0)")
							.setTooltip(order.contractId);
					}
				}
			});
		};

		const handleCloseOrder = (data) => {
			// console.log("SOCKET CLOSE ORDER", data);
			if (data.symbol === symbolInfoRef.current.symbol) {
				if (slOrderLineRef.current) {
					slOrderLineRef.current.remove();
					slOrderLineRef.current = null;
				}
				if (tpOrderLineRef.current) {
					tpOrderLineRef.current.remove();
					tpOrderLineRef.current = null;
				}
				if (orderRef.current) {
					orderRef.current.remove();
					orderRef.current = null;
				}
				orderPosition = false;
			}

			const orderKey = `order_${data.contractId}`;
			const slKey = `orderSL_${data.contractId}`;
			const tpKey = `orderTP_${data.contractId}`;

			// Eliminar la línea de orden
			if (existingOrdersRef.current[orderKey]) {
				existingOrdersRef.current[orderKey].remove();
				delete existingOrdersRef.current[orderKey];
			}

			// Eliminar la línea de Stop Loss
			if (existingOrdersRef.current[slKey]) {
				existingOrdersRef.current[slKey].remove();
				delete existingOrdersRef.current[slKey];
			}

			// Eliminar la línea de Take Profit
			if (existingOrdersRef.current[tpKey]) {
				existingOrdersRef.current[tpKey].remove();
				delete existingOrdersRef.current[tpKey];
			}
		};

		const handleNewOrder = (data) => {
			slOrderLineRef.current.remove();
			slOrderLineRef.current = null;
			tpOrderLineRef.current.remove();
			tpOrderLineRef.current = null;
			orderRef.current.remove();
			orderRef.current = null;
			orderPosition = false;
		};

		const handleModifyPositions = (data) => {
			// console.log("SOCKET MODIFY POSITIONS", data);
			const { contractId, modify } = data;
			if (modify === "SL" && data.priceSL === null) {
				removeLine(contractId, "stopLoss");
			} else if (modify === "TP" && data.priceTP === null) {
				removeLine(contractId, "takeProfit");
			}
		};

		const handleBalance = (data) => {
			// console.log("BALANCE", data);
			// const dataBalance = JSON.parse(data);
			// if (!dataBalance.balance) return;

			// const balance = data;
			// const account = dataBalance.account;

			// const selectedAccount = JSON.parse(localStorage.getItem("selectedAccount")) || {};
			// if (account === selectedAccount.account) {
			store.getState().setBalance(Number(data).toFixed(2));
			// }
		};

		const handleMessages = (data, callback) => {
			const message = data.message;
			if (message === "refresh") {
				window.location.reload();
				return;
			} else if (message === "Tu compra ha sido procesada exitosamente") {
				window.location.reload();
				toast.success("Tu compra ha sido procesada exitosamente", {
					position: "top-center",
					autoClose: true,
					closeOnClick: false,
					pauseOnHover: false,
					draggable: false,
					theme: "dark",
				});
				return;
			} else if (message && message.toLowerCase().includes("versión")) {
				toast.error(
					<div>
						{message}
						<button
							style={{
								marginLeft: "10px",
								padding: "5px 10px",
								backgroundColor: "#ff4d4f",
								color: "#fff",
								border: "none",
								borderRadius: "4px",
								cursor: "pointer",
							}}
							onClick={() => {
								store.getState().reset();
								localStorage.removeItem("token");
								localStorage.removeItem("selectedAccount");
								window.location.href = "/login";
								window.location.reload();
							}}>
							Hacelo Automáticamente
						</button>
					</div>,
					{
						position: "top-center",
						autoClose: false,
						closeOnClick: false,
						pauseOnHover: true,
						draggable: false,
						progress: undefined,
						closeButton: true,
						theme: "dark",
					}
				);
			} else {
				toast.error(message, {
					position: "top-center",
					autoClose: false,
					closeOnClick: false,
					pauseOnHover: true,
					draggable: false,
					progress: undefined,
					closeButton: true,
					theme: "dark",
				});
			}

			if (callback) {
				callback(true);
			}
		};

		const handleOptions = (data) => {
			console.log("SOCKET OPTIONS", data);
			if (data.length === 0) {
				return;
			}

			const tvWidget = store.getState().chart;
			const symbolInfo = store.getState().symbolInfo;

			if (symbolInfo === null || tvWidget === null) {
				setTimeout(() => {
					handleOptions(data);
				}, 500);
				return;
			}

			data.forEach((option) => {
				if (option.symbol === symbolInfo.symbol) {
					// if (option.contractType && option.contractType !== "accumulators") {
					// 	accumBodyRef.current.contractId = option.contractId;
					// console.log("OPTION", option);
					// }
					if (option.status === "win" || option.status === "loss") {
						const resultColor = option.status === "win" ? "green" : "red";
						if (option.optionType === "riseFall") {
							checkOptionResult(option, tvWidget, resultColor);
						}
					} else {
						handleOpenOption(option);

						// if (option.optionType === "riseFall") {
						// 	const startTime = option.entryTime;
						// 	const endTime = startTime + option.duration;
						// 	const price = option.optionType === "riseFall" ? option.entryPrice : Number(option.entryPrice) + Number(option.barrier);

						// 	tvWidget.activeChart().createMultipointShape(
						// 		[
						// 			{ time: startTime, price: price },
						// 			{ time: endTime, price: price },
						// 		],
						// 		{
						// 			shape: "trend_line",
						// 			lock: true,
						// 			disableSelection: true,
						// 			disableSave: true,
						// 			disableUndo: true,
						// 			text: `${option.contractType.toUpperCase()} - Pending`,
						// 			overrides: {
						// 				linecolor: "orange",
						// 				linewidth: 0.5,
						// 				statsPosition: 3,
						// 				textcolor: "white",
						// 				fontsize: 12,
						// 				bold: true,
						// 			},
						// 		}
						// 	);
						// }
					}
				}
			});
		};

		const checkOptionResult = async (option, tvWidget, resultColor) => {
			tvWidget.activeChart().createMultipointShape(
				[
					{ time: option.entryTime, price: option.entryPrice },
					{ time: option.closeTime, price: option.entryPrice },
				],
				{
					shape: "trend_line",
					lock: true,
					disableSelection: true,
					disableSave: true,
					disableUndo: true,
					text: `${option.contractType} - Pending`,
					overrides: {
						linecolor: resultColor,
						linewidth: 0.5,
						statsPosition: 3,
						textcolor: "white",
						fontsize: 12,
						bold: true,
					},
				}
			);

			tvWidget.activeChart().createMultipointShape(
				[
					{ time: option.closeTime, price: option.entryPrice },
					{ time: option.closeTime, price: option.closePrice },
				],
				{
					shape: "trend_line",
					lock: true,
					disableSelection: true,
					disableSave: true,
					disableUndo: true,
					overrides: {
						linecolor: resultColor,
						linestyle: 2, // Dotted line
						linewidth: 1,
					},
				}
			);

			// const profit = option.profit < 0 ? option.profit : option.profit - option.tradeAmount;

			tvWidget.activeChart().createShape(
				{ time: option.closeTime, price: option.closePrice },
				{
					shape: "balloon",
					text: option.profit,
					overrides: {
						backgroundColor: resultColor,
						color: "white",
						fontsize: 20,
						bold: true,
					},
					zOrder: "top",
				}
			);
		};

		socket.on("allLines", handleAllLines);
		socket.on("allOptions", handleOptions);
		socket.on("allOrders", handleAllOrder);
		socket.on("newOrder", handleNewOrder);
		socket.on("closeLine", handleClosePosition);
		socket.on("closeOrder", handleCloseOrder);
		socket.on("modifyPosition", handleModifyPositions);
		socket.on("balance", handleBalance);
		socket.on("messages", (data, callback) => {
			handleMessages(data, callback);
		});

		return () => {
			socket.off("allLines", handleAllLines);
			socket.off("allOptions", handleOptions);
			socket.off("allOrders", handleAllOrder);
			socket.off("newOrder", handleNewOrder);
			socket.off("closeLine", handleClosePosition);
			socket.off("closeOrder", handleCloseOrder);
			socket.off("modifiyPositions", handleModifyPositions);
			socket.off("balance", handleBalance);
			socket.off("messages", handleMessages);
		};
	}, []);

	function updateStopLoss(contractId, price, sA, pipsSL, slProfit) {
		console.log("updateStopLoss", contractId, price, pipsSL, slProfit);
		socket.emit("modifyPosition", {
			account: sA.account,
			userId: sA.userId,
			contractId: contractId,
			priceSL: price,
			modify: "SL",
			pipsSL: pipsSL.toFixed(2),
			slProfit: slProfit.toFixed(2),
		});
	}

	function updateTakeProfit(contractId, price, sA, pipsTP, tpProfit) {
		try {
			socket.emit(
				"modifyPosition",
				{
					account: sA.account,
					userId: sA.userId,
					contractId: contractId,
					priceTP: price,
					modify: "TP",
					pipsTP: pipsTP,
					tpProfit: tpProfit,
				},
				(response) => {
					console.log("Socket response:", response);
				}
			);

			return true;
		} catch (error) {
			console.error("Socket emission error:", error);
			return false;
		}
	}

	//==============================================// ORDER LINES
	useEffect(() => {
		if (!tvWidget) return;
		tvWidgetRef.current = tvWidget;
	}, [tvWidget]);

	window.drawOrderLines = async (price) => {
		if (!tvWidget) return;
		orderPosition = true;
		// const price = Number(lastBarRef.current.close);

		const percentageDistance = 0.001;
		price = Number(price);
		const distance = price * percentageDistance;

		SLOrder.current = price + distance;
		pipsOrderSL.current = Math.abs(price - SLOrder.current);

		TPOrder.current = price - distance;
		pipsOrderTP.current = Math.abs(price - TPOrder.current);

		const symbolInfo = store.getState().symbolInfo;
		const riskAmount = store.getState().riskAmount || localStorage.getItem("riskAmount");

		console.log("ORDER DATA", price, SLOrder.current, riskAmount, symbolInfo.derivMultiplier);
		derivCalcSLRef.current = await window.calculateTradeAmount(price, SLOrder.current, riskAmount, symbolInfo.derivMultiplier);
		const tradeAmount = derivCalcSLRef.current.tradeAmount;
		setTradeAmount(tradeAmount.toFixed(2));

		let lastPrice = price;

		if (orderRef.current) {
			orderRef.current.remove();
			orderRef.current = null;
			orderPosition = false;
		} else {
			orderRef.current = tvWidget
				.chart()
				.createOrderLine()
				.onMove(function () {
					let newPrice = this.getPrice();

					let priceChange = newPrice - lastPrice;

					// Mover SL y TP la misma cantidad que la línea de Orden
					if (slOrderLineRef.current) {
						slOrderLineRef.current.setPrice(SLOrder.current + priceChange);
					}
					if (tpOrderLineRef.current) {
						tpOrderLineRef.current.setPrice(TPOrder.current + priceChange);
					}

					const riskAmount = store.getState().riskAmount || localStorage.getItem("riskAmount");
					console.log("ORDER DATA", riskAmount);
					this.setText(`ORDER: RISK ${riskAmount} `);

					SLOrder.current += priceChange;
					TPOrder.current += priceChange;
					lastPrice = newPrice;
				})
				.onCancel(function () {
					console.log("onCancel called");
					slOrderLineRef.current.remove();
					slOrderLineRef.current = null;
					tpOrderLineRef.current.remove();
					tpOrderLineRef.current = null;
					orderRef.current.remove();
					orderRef.current = null;
					orderPosition = false;
				})
				.setText(`ORDER: RISK $${riskAmount} `)
				.setQuantity(Number(tradeAmount).toFixed(2))
				.setPrice(price)
				.setLineColor("#4599d9")
				.setQuantityBorderColor("#4599d9")
				.setBodyBorderColor("#4599d9")
				.setBodyTextColor("#a9a9a9")
				.setQuantityTextColor("#a9a9a9")
				.setBodyBackgroundColor("rgba(0, 0, 0, 0)")
				.setQuantityBackgroundColor("rgba(0, 0, 0, 0)")
				.setCancelButtonBackgroundColor("rgba(0, 0, 0, 0)")
				.setCancelButtonIconColor("#4599d9")
				.setCancelButtonBorderColor("#4599d9");
		}

		if (slOrderLineRef.current) {
			slOrderLineRef.current.remove();
			slOrderLineRef.current = null;
		} else {
			slOrderLineRef.current = tvWidget
				.chart()
				.createOrderLine()
				.onMove(async function () {
					const priceNew = orderRef.current.getPrice();
					SLOrder.current = slOrderLineRef.current.getPrice();
					pipsOrderSL.current = Math.abs(priceNew - SLOrder.current);

					const symbolInfo = await JSON.parse(localStorage.getItem("symbolInfo"));
					const multipliers = await window.calcMultipliers(symbolInfo, SLOrder.current, priceNew);
					const riskAmount = store.getState().riskAmount || localStorage.getItem("riskAmount");

					derivCalcSLRef.current = await window.calculateTradeAmount(
						priceNew,
						SLOrder.current,
						riskAmount,
						multipliers.chosenDerivMultiplier
					);
					multiplierRef.current = multipliers.chosenMultiplier;
					derivMultiplierRef.current = multipliers.chosenDerivMultiplier;
					store.getState().setMultiplier(derivMultiplierRef.current);
					tradeAmountRef.current = derivCalcSLRef.current.tradeAmount;
					setTradeAmount(derivCalcSLRef.current.tradeAmount.toFixed(2));

					this.setQuantity(Number(derivCalcSLRef.current.tradeAmount).toFixed(2));
					orderRef.current.setQuantity(Number(derivCalcSLRef.current.tradeAmount).toFixed(2));
					slProfit.current = Number(riskAmount);
					this.setText(
						`ORDER SL: $${derivCalcSLRef.current.totalRisk.toFixed(2)} -- Pips: ${derivCalcSLRef.current.priceDifference.toFixed(
							2
						)} -- X: ${multipliers.chosenDerivMultiplier}`
					);

					if (tpOrderLineRef.current) {
						TPOrder.current = tpOrderLineRef.current.getPrice();
						derivCalcTPRef.current = await calculateMultiplierProfit(
							priceNew,
							TPOrder.current,
							tradeAmountRef.current,
							derivMultiplierRef.current
						);

						tpOrderLineRef.current.setText(
							`ORDER TP: $${derivCalcTPRef.current.profit} -- Pips: ${derivCalcTPRef.current.pips} -- Payout: ${derivCalcTPRef.current.payout}`
						);
						tpOrderLineRef.current.setQuantity(Number(derivCalcSLRef.current.tradeAmount).toFixed(2));
					}
				})
				.onCancel(function () {})
				.setText(`ORDER SL: $${riskAmount} -- Pips: ${pipsOrderSL.current.toFixed(2)}  X: ${derivMultiplierRef.current}`)
				.setQuantity(Number(tradeAmount).toFixed(2))
				.setPrice(SLOrder.current)
				.setLineColor("#cc2e3d")
				.setQuantityBorderColor("#cc2e3d")
				.setBodyTextColor("#cc2e3d")
				.setBodyBorderColor("#cc2e3d")
				.setBodyBackgroundColor("rgba(0, 0, 0, 0)")
				.setQuantityBackgroundColor("rgba(0, 0, 0, 0)")
				.setCancelButtonBackgroundColor("rgba(0, 0, 0, 0)")
				.setCancelButtonIconColor("#cc2e3d")
				.setCancelButtonBorderColor("#cc2e3d");
		}

		if (tpOrderLineRef.current) {
			tpOrderLineRef.current.remove();
			tpOrderLineRef.current = null;
		} else {
			tpOrderLineRef.current = tvWidget
				.chart()
				.createOrderLine()
				.onMove(async function () {
					const priceNew = orderRef.current.getPrice();
					TPOrder.current = tpOrderLineRef.current.getPrice();

					derivCalcTPRef.current = await calculateMultiplierProfit(
						priceNew,
						TPOrder.current,
						tradeAmountRef.current,
						derivMultiplierRef.current
					);

					this.setText(
						`ORDER TP: $${derivCalcTPRef.current.profit} -- Pips: ${derivCalcTPRef.current.pips} -- Payout: ${derivCalcTPRef.current.payout}`
					);
				})
				.onCancel(function () {})
				.setText(`ORDER TP: $${riskAmount} -- Pips: ${pipsOrderTP.current.toFixed(2)}`)
				.setQuantity(Number(tradeAmountRef.current).toFixed(2))
				.setPrice(TPOrder.current)
				.setLineColor("#01a79e")
				.setQuantityBorderColor("#01a79e")
				.setBodyBorderColor("#01a79e")
				.setBodyTextColor("#01a79e")
				.setBodyBackgroundColor("rgba(0, 0, 0, 0)")
				.setQuantityBackgroundColor("rgba(0, 0, 0, 0)")
				.setCancelButtonBackgroundColor("rgba(0, 0, 0, 0)")
				.setCancelButtonIconColor("#01a79e")
				.setCancelButtonBorderColor("#01a79e");
		}
	};

	window.handleInputChange = async (event) => {
		if (!event || !event.target) {
			console.error("Evento o target del evento no definido");
			return;
		}

		await localStorage.setItem("inputValue", event.target.value);

		if (timer) {
			clearTimeout(timer);
		}
		const newTimer = setTimeout(() => {
			window.calculateAmount(event.target.value);
		}, 500);

		setTimer(newTimer);

		if (chartType === "Opciones") {
			handleProposal();
		}
	};

	window.calculateAmount = async (value) => {
		let porcent = Number(value) / 100;
		const balance = store.getState().balance;
		actualBalanceRef.current = Number(balance);
		calculatedValueRef.current = actualBalanceRef.current * porcent;

		if (calculatedValueRef.current < 1) {
			toast.error("El porcentaje debe ser al menos 1", {
				position: "top-right",
				autoClose: 3000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				theme: "dark",
			});
			return;
		}

		store.getState().setRiskAmount(calculatedValueRef.current.toFixed(2));
		localStorage.setItem("riskAmount", calculatedValueRef.current.toFixed(2));
		store.getState().setRiskAmount(calculatedValueRef.current.toFixed(2));
		riskAmountRef.current = calculatedValueRef.current.toFixed(2);

		const executeCalculation = async () => {
			const price = Number(lastBarRef.current.close);
			const tAmount = await window.calculateTradeAmount(price, SL.current, riskAmountRef.current, derivMultiplierRef.current);
			if (slLineRef.current) {
				slLineRef.current.setText(`SL: ${riskAmountRef.current} -- Pips: ${pipsSL.current.toFixed(2)}  X: ${derivMultiplierRef.current}`);
				slLineRef.current.setQuantity(Number(tAmount.tradeAmount).toFixed(2));
			}

			const calcProfit = await calculateMultiplierProfit(price, TP.current, tAmount.tradeAmount, derivMultiplierRef.current);

			if (tpLineRef.current) {
				tpLineRef.current.setText(`TP: $${calcProfit.profit} -- Pips: ${calcProfit.pips} -- Payout: ${calcProfit.payout}`);
				tpLineRef.current.setQuantity(Number(tAmount.tradeAmount).toFixed(2));
			}
		};

		if (lastBarRef.current) {
			executeCalculation();
		} else {
			const retryCalculation = (attempts = 0, maxAttempts = 5) => {
				setTimeout(() => {
					if (lastBarRef.current) {
						executeCalculation();
					} else if (attempts < maxAttempts) {
						retryCalculation(attempts + 1);
					}
				}, 800);
			};
			retryCalculation();
		}
	};

	window.buyMultUpMultDn = async (contractType) => {
		const currentPrice = lastBarRef.current.close;
		const profitBoost = await store.getState().profitBoost;
		const symbolInfo = await JSON.parse(localStorage.getItem("symbolInfo"));

		if (!orderPosition) {
			if (!retCandles && !slTpButton && !profitBoost) {
				toast.error("No tienes un metodo de gestión de riesgo seleccionado");
				return;
			}
		}

		if (!selectedAccount || !selectedAccount.account) {
			toast.error("No tienes una cuenta seleccionada");
			return;
		}

		if (!symbolInfo || !symbolInfo.symbol) {
			toast.error("No se ha seleccionado un símbolo válido");
			return;
		}

		if (!user || !user.id) {
			toast.error("No se ha identificado un usuario válido");
			return;
		}

		if (contractType === "MULTDOWN" && slLineRef.current && slLineRef.current.getPrice() < currentPrice) {
			toast.error("Para operaciones de venta, el Stop Loss no puede ser menor que el precio actual");
			return;
		}

		if (contractType === "MULTUP" && tpLineRef.current && tpLineRef.current.getPrice() < currentPrice) {
			toast.error("Para operaciones de compra, el Take Profit no puede ser menor que el precio actual");
			return;
		}

		let price = await Number(store.getState().lastBar.close);
		let orderPrice;
		let orderType;

		const showToast = (condition, orderMessage, priceMessage) => {
			if (condition) {
				toast.error(orderPosition ? orderMessage : priceMessage);
				return true;
			}
			return false;
		};

		if (contractType === "MULTDOWN") {
			if (orderPosition) {
				orderPrice = orderRef.current.getPrice();
				orderType = orderPrice > price ? "Stop" : "Limit";
				if (showToast(orderRef.current.getPrice() > SLOrder.current, "La orden debe ser menor al Stop Loss", null)) return;
				if (showToast(orderRef.current.getPrice() < TPOrder.current, "La orden debe ser mayor al Take Profit", null)) return;
			}
		}

		if (contractType === "MULTUP") {
			if (orderPosition) {
				orderPrice = orderRef.current.getPrice();
				orderType = orderPrice < price ? "Stop" : "Limit";
				if (showToast(orderRef.current.getPrice() < SLOrder.current, "La orden debe ser mayor al Stop Loss", null)) return;
				if (showToast(orderRef.current.getPrice() > TPOrder.current, "La orden debe ser menor al Take Profit", null)) return;
			}
		}

		const pipsSLValue = pipsSL.current || 0;
		const slPrice = store.getState().slPrice;
		const tpPrice = store.getState().tpPrice;

		const baseAmount = await store.getState().baseAmount;
		const distance = await store.getState().distanceInPips;
		const positions = await store.getState().positions;
		const rAmount = await store.getState().riskAmount;

		const body = {
			stopLoss: orderPosition
				? SLOrder.current.toFixed(2)
				: slLineRef.current && slPrice
				? slPrice
				: slRetCandlesRef.current
				? slRetCandlesRef.current.getPrice()
				: null,
			takeProfit: orderPosition
				? TPOrder.current.toFixed(2)
				: tpLineRef.current && tpPrice
				? tpPrice
				: tpRetCandlesRef.current
				? tpRetCandlesRef.current.getPrice()
				: null,
			stopOut: Number(symbolInfo.stopOut),
			commission,
			totalRisk: riskAmountTotalRef.current,
			contractType,
			type: contractType === "MULTUP" ? (orderPosition ? "Buy " + orderType : "Buy") : orderPosition ? "Sell " + orderType : "Sell",
			symbol: symbolInfo.symbol,
			symbolName: symbolInfo.displayName,
			pip: Number(symbolInfo.pip),
			pipValue: newPipValueRef.current || 0,
			userId: user.id,
			tradeAmount: Number(tradeAmountRef.current).toFixed(2) || 0,
			account: selectedAccount.account,
			derivMultiplier: derivMultiplierRef.current,
			multiplier: multiplierRef.current,
			riskAmount: rAmount,
			pipsSL: orderPosition ? pipsOrderSL.current.toFixed(2) : pipsSLValue.toFixed(2),
			pipsTP: orderPosition ? pipsOrderTP.current.toFixed(2) : pipsTP.current ? pipsTP.current.toFixed(2) : null,
			tpProfit: tpProfit.current ? tpProfit.current.toFixed(2) : null,
			slProfit: slProfit.current ? slProfit.current.toFixed(2) : null,
			payout: derivCalcTPRef.current?.payout || 0,
			preStopLoss: preStopLoss ? preStopLoss : null,
			orderType: orderPosition ? orderType : "Market",
			orderPrice: orderPosition ? orderPrice : "0",
			orderPosition: orderPosition,
			copyTrader: user.copyTrader,
			copyTraderMT5: user.copyTraderMT5,
			...(profitBoost && {
				baseAmount: baseAmount,
				distance: distance,
				positions: positions,
				profitBoost: profitBoost,
				multipliers: {
					derivMultiplier: symbolInfo.derivMultiplier,
					derivMultiplier2: symbolInfo.derivMultiplier2,
					derivMultiplier3: symbolInfo.derivMultiplier3,
					derivMultiplier4: symbolInfo.derivMultiplier4,
					derivMultiplier5: symbolInfo.derivMultiplier5,
					multiplier1: symbolInfo.multiplier1,
					multiplier2: symbolInfo.multiplier2,
					multiplier3: symbolInfo.multiplier3,
					multiplier4: symbolInfo.multiplier4,
					multiplier5: symbolInfo.multiplier5,
				},
			}),
		};
		console.table([
			{
				TPOrder: TPOrder.current?.toFixed(2),
				"tpLineRef & tpPrice": tpPrice,
				tpRetCandlesRef: tpRetCandlesRef.current?.getPrice(),
			},
		]);
		console.log("CONTRACT BODY", body.takeProfit);
		// return;
		if (orderPosition) {
			socket.emit("buyMultUpMultDn", body);

			if (slOrderLineRef.current) {
				slOrderLineRef.current.remove();
				slOrderLineRef.current = null;
			}
			if (tpOrderLineRef.current) {
				tpOrderLineRef.current.remove();
				tpOrderLineRef.current = null;
			}
			if (orderRef.current) {
				orderRef.current.remove();
				orderRef.current = null;
			}
			orderPosition = false;
		} else {
			socket.emit("buyMultUpMultDn", body);

			if (slLineRef.current) {
				slLineRef.current.remove();
				slLineRef.current = null;
			}

			if (tpLineRef.current) {
				tpLineRef.current.remove();
				tpLineRef.current = null;
			}

			store.getState().setSlTpButton(false);
			store.getState().setRetCandles(false);
		}

		if (slRetCandlesRef.current) {
			slRetCandlesRef.current.remove();
			slRetCandlesRef.current = null;
		}
		if (tpRetCandlesRef.current) {
			tpRetCandlesRef.current.remove();
			tpRetCandlesRef.current = null;
		}

		if (body.profitBoost) {
			window.removePreviewPB();
		}
	};

	async function buyOptions(type) {
		if (!selectedAccount || !selectedAccount.token) {
			toast.error("Selecciona una cuenta antes de realizar una operación", {
				position: "top-right",
				autoClose: 2000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				theme: "dark",
			});
			return;
		}

		if (!selectedAccount || !selectedAccount.account) {
			toast.error("No tienes una cuenta seleccionada", {
				position: "top-right",
				autoClose: 2000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				theme: "dark",
			});
			return;
		}

		if (!symbolInfoRef.current || !symbolInfoRef.current.symbol) {
			toast.error("No se ha seleccionado un símbolo válido", {
				position: "top-right",
				autoClose: 2000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				theme: "dark",
			});
			return;
		}

		if (!user || !user.id) {
			toast.error("No se ha identificado un usuario válido", {
				position: "top-right",
				autoClose: 2000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				theme: "dark",
			});
			return;
		}
		switch (contractTypeRef.current) {
			case "riseFall":
				const rfBody = {
					optionType: "riseFall",
					contractType: type,
					symbol: symbolInfoRef.current.symbol,
					symbolName: symbolInfoRef.current.displayName,
					userId: user.id,
					tradeAmount: riskAmountRef.current,
					account: selectedAccount.account,
					duration: sliderRef.current,
					basis: "stake",
				};
				socket.emit("buyOptions", rfBody);
				break;
			case "higherLower":
				const hlBody = {
					optionType: "higherLower",
					contractType: type,
					symbol: symbolInfoRef.current.symbol,
					symbolName: symbolInfoRef.current.displayName,
					userId: user.id,
					tradeAmount: riskAmountRef.current,
					account: selectedAccount.account,
					duration: sliderRef.current,
					basis: "stake",
					barrier: barrierRef.current,
				};
				socket.emit("buyOptions", hlBody);
				break;
			case "touchNoTouch":
				const tntBody = {
					optionType: "touchNoTouch",
					contractType: type,
					symbol: symbolInfoRef.current.symbol,
					symbolName: symbolInfoRef.current.displayName,
					userId: user.id,
					tradeAmount: riskAmountRef.current,
					account: selectedAccount.account,
					duration: sliderRef.current,
					basis: "stake",
					barrier: barrierRef.current,
				};
				socket.emit("buyOptions", tntBody);
				break;
			case "accumulators":
				accumBodyRef.current = {
					optionType: "accumulators",
					contractType: type,
					symbol: symbolInfoRef.current.symbol,
					symbolName: symbolInfoRef.current.displayName,
					userId: user.id,
					tradeAmount: riskAmountRef.current,
					account: selectedAccount.account,
					basis: "stake",
					growthRate: Number(sliderRef.current) / 100,
					contractId: accumBodyRef.current === null ? null : accumBodyRef.current.contractId,
					profitTick: (profitTick * ((Number(sliderRef.current) / 100) * Number(riskAmountRef.current))).toFixed(2),
				};
				socket.emit("buyOptions", accumBodyRef.current);
				break;
			default:
				toast.error("Tipo de contrato no válido");
				return;
		}
	}

	window.calculateCandleSizes = async () => {
		const ohlcData5M = await getLastCloses(300, 25);
		const lastTwentyCandles = ohlcData5M.slice(-20);
		const priceMovements = lastTwentyCandles.map((candle) =>
			Math.abs(candle.close - (candle.open || lastTwentyCandles[lastTwentyCandles.indexOf(candle) - 1]?.close || candle.close))
		);
		const mean = priceMovements.reduce((a, b) => a + b, 0) / priceMovements.length;
		const variance = priceMovements.reduce((a, b) => a + Math.pow(b - mean, 2), 0) / priceMovements.length;
		const stdDev = Math.sqrt(variance);
		const normalCandleSizes = priceMovements.filter((movement) => movement <= mean + 2 * stdDev);
		return normalCandleSizes.reduce((a, b) => a + b, 0) / normalCandleSizes.length;
	};

	window.setBreakEven = () => {
		const openPositions = store.getState().openPositions;
		const symbolInfo = store.getState().symbolInfo;
		const position = modifingPosRef.current;

		if (!openPositions || !symbolInfo) return;

		// openPositions.forEach((position) => {
		if (position.symbol === symbolInfo.symbol) {
			const contractId = position.contractId;
			let newStopLossPrice;

			if (position.type === "Buy") {
				newStopLossPrice = position.entryPrice + 1 * position.pip;

				if (lastBarRef.current.close < position.entryPrice) {
					return;
				}
			} else if (position.type === "Sell") {
				newStopLossPrice = position.entryPrice - 5 * position.pip;

				if (lastBarRef.current.close > position.entryPrice) {
					return;
				}
			} else {
				return;
			}

			const tradeAmount = position.tradeAmount;
			const stopOut = position.stopOut;
			const pipsSL = 5 * position.pip;
			const multiplier = position.multiplier;
			const slProfit = (tradeAmount / stopOut) * pipsSL * multiplier;

			const selectedAccount = JSON.parse(localStorage.getItem("selectedAccount"));
			updateStopLoss(contractId, newStopLossPrice, selectedAccount, pipsSL, slProfit);
		}
		// });
	};

	window.turnOffRiskLines = () => {
		if (!tvWidget) return;
		if (slRetCandlesRef.current) {
			slRetCandlesRef.current.remove();
			slRetCandlesRef.current = null;
			store.getState().setRetCandles(false);
		}
		if (tpRetCandlesRef.current) {
			tpRetCandlesRef.current.remove();
			tpRetCandlesRef.current = null;
			store.getState().setRetCandles(false);
		}

		if (slLineRef.current) {
			slLineRef.current.remove();
			slLineRef.current = null;
			store.getState().setSlTpButton(false);
		}

		if (tpLineRef.current) {
			tpLineRef.current.remove();
			tpLineRef.current = null;
			store.getState().setSlTpButton(false);
		}
	};

	window.removePreviewPB = () => {
		const shapes = tvWidget.chart().getAllShapes();
		const detailedShapes = shapes
			.map((shape, index) => {
				const shapeDetails = tvWidget.chart().getShapeById(shape.id);
				const properties = shapeDetails ? shapeDetails.getProperties() : {};
				const points = shapeDetails ? shapeDetails.getPoints() : [];
				return {
					id: shape.id,
					name: shape.name,
					points: points.map((p) => ({ time: p.time, price: p.price })),
					properties: properties,
				};
			})
			.filter((shape) => shape.properties.text && shape.properties.text.includes("POSITION"));

		if (detailedShapes.length > 0) {
			detailedShapes.forEach((shape) => {
				try {
					tvWidget.activeChart().removeEntity(shape.id);
				} catch (error) {
					console.error(`Error eliminando la forma con ID ${shape.id}:`, error);
				}
			});
			return true;
		} else {
			return false;
		}
	};

	window.drawPreviewPB = async (contractType) => {
		const tvWidget = store.getState().chart;

		const removedLinesPb = window.removePreviewPB();
		console.log("REMOVED LINES", removedLinesPb);
		if (removedLinesPb) {
			if (slLineRef.current) {
				slLineRef.current.remove();
				slLineRef.current = null;
			}

			if (tpLineRef.current) {
				tpLineRef.current.remove();
				tpLineRef.current = null;
			}
			return;
		}

		const symbolInfo = await JSON.parse(localStorage.getItem("symbolInfo"));
		const priceNew = Number(store.getState().lastBar.close);
		const distanceInPips = await store.getState().distanceInPips;
		const baseAmount = await Number(store.getState().baseAmount);
		const positions = store.getState().positions;
		let multipliers = 0;
		let totalDistance = distanceInPips * positions;
		let totalSl = priceNew - totalDistance;
		const entries = [];

		if (!baseAmount) {
			toast.dark("Debes establecer Posiciones o Distancia", {
				position: "top-right",
				autoClose: 2000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
			});
			return;
		}

		if (contractType === "MULTUP") {
			totalDistance = distanceInPips * positions;
			totalSl = priceNew - totalDistance;

			for (let i = 0; i < positions; i++) {
				const targetPrice = priceNew - i * distanceInPips;
				multipliers = await window.calcMultipliers(symbolInfo, totalSl, targetPrice);

				const tradeAmountResult = await window.calculateTradeAmount(
					targetPrice,
					totalSl.toFixed(2),
					baseAmount,
					multipliers.chosenDerivMultiplier
				);
				const tradeAmount = tradeAmountResult.tradeAmount.toFixed(2);

				if (Number(tradeAmount) < 1) {
					toast.dark("El monto de la operación debe ser mayor a $1", {
						position: "top-right",
						autoClose: 2000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: false,
						draggable: true,
					});
					return;
				}

				entries.push({
					targetPrice,
					tradeAmount,
					multiplier: multipliers.chosenDerivMultiplier,
					positionIndex: i + 1,
				});

				tvWidget.chart().createShape(
					{ time: tvWidget.activeChart().getVisibleRange().from, price: targetPrice },
					{
						shape: "horizontal_line",
						overrides: {
							linecolor: "#01a79e",
							linewidth: 0.5,
							linestyle: 2,
							showLabel: true,
							title: `preview_${i + 1}`,
							text: `< < POSITION ${i + 1} - RIESGO $${baseAmount.toFixed(2)} - TRADE ${tradeAmount} - X ${
								multipliers.chosenDerivMultiplier
							} > >`,
							textcolor: "#01a79e",
							fontsize: 11,
							fontFamily: "'Orbitron', sans-serif",
							zOrder: "top",
							lineLeap: 2,
						},
					}
				);
			}
		} else {
			totalDistance = distanceInPips * positions;
			totalSl = priceNew + totalDistance;

			for (let i = 0; i < positions; i++) {
				const targetPrice = priceNew + i * distanceInPips;
				multipliers = await window.calcMultipliers(symbolInfo, totalSl, targetPrice);

				const tradeAmountResult = await window.calculateTradeAmount(
					targetPrice,
					totalSl.toFixed(2),
					baseAmount,
					multipliers.chosenDerivMultiplier
				);
				const tradeAmount = tradeAmountResult.tradeAmount.toFixed(2);
				if (Number(tradeAmount) < 1) {
					toast.dark("El monto de la operación debe ser mayor a $1", {
						position: "top-right",
						autoClose: 2000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: false,
						draggable: true,
					});
					return;
				}
				entries.push({
					targetPrice: targetPrice.toFixed(2),
					tradeAmount: tradeAmount,
					multiplier: multipliers.chosenDerivMultiplier,
					positionIndex: i + 1,
				});

				tvWidget.chart().createShape(
					{ time: tvWidget.activeChart().getVisibleRange().from, price: targetPrice },
					{
						shape: "horizontal_line",
						name: `preview_${i + 1}`,
						overrides: {
							linecolor: "#cc2e3d",
							linewidth: 0.5,
							linestyle: 2,
							showLabel: true,
							text: `< < POSITION ${i + 1} - $${baseAmount.toFixed(2)} - TRADE AMOUNT ${tradeAmount} - X ${
								multipliers.chosenDerivMultiplier
							} > >`,
							textcolor: "#cc2e3d",
							fontsize: 11,
							zOrder: "top",
							lineLeap: 2,
						},
					}
				);
			}
		}

		const firstMultipliers = await window.calcMultipliers(symbolInfo, totalSl, priceNew);
		await window.calculateTradeAmount(priceNew, totalSl.toFixed(2), baseAmount, firstMultipliers.chosenDerivMultiplier);
		derivMultiplierRef.current = firstMultipliers.chosenDerivMultiplier;
		entriesRef.current = entries;

		const sumTotalTradeAmount = entries.reduce((acc, curr) => acc + Number(curr.tradeAmount), 0);
		store.getState().setTradeAmount(sumTotalTradeAmount.toFixed(2), totalSl);
		store.getState().setSlPrice(totalSl.toFixed(2));

		const rA = await localStorage.getItem("riskAmount");

		slLineRef.current = tvWidget
			.chart()
			.createOrderLine()
			.setText(`SL: $${rA} -- Pips: ${totalDistance.toFixed(2)}  X: ${multipliers.chosenDerivMultiplier}`)
			.setQuantity(Number(entries[0]?.tradeAmount || 0).toFixed(2))
			.setPrice(totalSl)
			.setLineColor("#cc2e3d")
			.setQuantityBorderColor("#cc2e3d")
			.setBodyTextColor("#cc2e3d")
			.setBodyBorderColor("#cc2e3d")
			.setBodyBackgroundColor("rgba(0, 0, 0, 0)")
			.setQuantityBackgroundColor("rgba(0, 0, 0, 0)")
			.setCancelButtonBackgroundColor("rgba(0, 0, 0, 0)")
			.setCancelButtonIconColor("#cc2e3d")
			.setCancelButtonBorderColor("#cc2e3d")
			.onMove(async function () {})
			.onCancel(function () {});
	};

	window.drawTakeProfitPB = async (tp) => {
		const tvWidget = store.getState().chart;
		const symbolInfo = await JSON.parse(localStorage.getItem("symbolInfo"));
		const priceNew = Number(store.getState().lastBar.close);
		const totalDistanceInPips = await store.getState().distanceInPips;
		const positions = store.getState().positions;
		const distanceInPips = totalDistanceInPips / positions;
		let totalTp = 0;
		let multipliers = 0;
		let totalDistance = 0;

		if (contractType === "MULTUP") {
			totalDistance = distanceInPips * tp;
			totalTp = priceNew + totalDistance;
			multipliers = await window.calcMultipliers(symbolInfo, totalTp, priceNew);

			derivCalcTPRef.current = await calculateMultiplierProfit(
				priceNew,
				totalTp,
				Number(store.getState().tradeAmount),
				derivMultiplierRef.current
			);
		} else {
			totalDistance = distanceInPips * tp;
			totalTp = priceNew - totalDistance;
			multipliers = await window.calcMultipliers(symbolInfo, totalTp, priceNew);

			derivCalcTPRef.current = await calculateMultiplierProfit(
				priceNew,
				totalTp,
				Number(store.getState().tradeAmount),
				derivMultiplierRef.current
			);
		}

		if (tp === 0) {
			tpLineRef.current.remove();
			tpLineRef.current = null;
			return;
		}

		if (tpLineRef.current) {
			tpLineRef.current.setPrice(totalTp);
			tpLineRef.current.setText(
				`TP: $${derivCalcTPRef.current.profit} -- Pips: ${derivCalcTPRef.current.pips} -- Payout: ${derivCalcTPRef.current.payout}`
			);
		} else {
			tpLineRef.current = tvWidget
				.chart()
				.createOrderLine()
				.onMove(async function () {
					const priceNew = Number(lastBarRef.current.close);
					TP.current = tpLineRef.current.getPrice();

					derivCalcTPRef.current = await calculateMultiplierProfit(
						priceNew,
						TP.current,
						tradeAmountRef.current,
						derivMultiplierRef.current
					);

					this.setText(
						`TP: $${derivCalcTPRef.current.profit} -- Pips: ${derivCalcTPRef.current.pips} -- Payout: ${derivCalcTPRef.current.payout}`
					);
				})
				.onCancel(function () {})
				.setText(`TP: $${derivCalcTPRef.current.profit} -- Pips: ${derivCalcTPRef.current.pips} -- Payout: ${derivCalcTPRef.current.payout}`)
				.setQuantity(store.getState().tradeAmount)
				.setPrice(totalTp)
				.setLineColor("#01a79e")
				.setQuantityBorderColor("#01a79e")
				.setBodyBorderColor("#01a79e")
				.setBodyTextColor("#01a79e")
				.setBodyBackgroundColor("rgba(0, 0, 0, 0)")
				.setQuantityBackgroundColor("rgba(0, 0, 0, 0)")
				.setCancelButtonBackgroundColor("rgba(0, 0, 0, 0)")
				.setCancelButtonIconColor("#01a79e")
				.setCancelButtonBorderColor("#01a79e");
		}
	};

	window.drawSLTPLines = async () => {
		store.getState().setProfitBoost(false);
		if (!tvWidget) return;

		if (slRetCandlesRef.current) {
			slRetCandlesRef.current.remove();
			slRetCandlesRef.current = null;
		}

		if (tpRetCandlesRef.current) {
			tpRetCandlesRef.current.remove();
			tpRetCandlesRef.current = null;
		}

		if (lastBarRef.current == null) {
			setTimeout(() => {
				window.drawSLTPLines();
				console.log("DRAWING SLTP LINES TIMEOUT", lastBarRef.current);
			}, 300);
			return;
		}

		const percentageDistance = 0.001;
		const price = Number(lastBarRef.current.close);
		const distance = price * percentageDistance;
		SL.current = price + distance;
		pipsSL.current = Math.abs(price - SL.current);
		store.getState().setSlPrice(pipsSL.current);

		const symbolInfo = store.getState().symbolInfo;
		console.log("DRAW TP SET TP PRICE", symbolInfo);

		derivCalcSLRef.current = await window.calculateTradeAmount(price, SL.current, riskAmount, symbolInfo.derivMultiplier);
		const tradeAmount = derivCalcSLRef.current.tradeAmount;
		setTradeAmount(tradeAmount.toFixed(2));

		TP.current = price - distance;
		store.getState().setTpPrice(TP.current);

		derivCalcTPRef.current = await calculateMultiplierProfit(price, TP.current, tradeAmount, symbolInfo.derivMultiplier);

		if (slLineRef.current) {
			slLineRef.current.remove();
			slLineRef.current = null;
			store.getState().setSlTpButton(false);
		} else {
			store.getState().setSlTpButton(true);
			setPreStopLoss(null);
			slLineRef.current = tvWidget
				.chart()
				.createOrderLine()
				.onMove(async function () {
					const priceNew = Number(lastBarRef.current.close);
					SL.current = slLineRef.current.getPrice();
					const symbolInfo = await JSON.parse(localStorage.getItem("symbolInfo"));
					const multipliers = await window.calcMultipliers(symbolInfo, SL.current, priceNew);
					const riskAmount = Number(store.getState().riskAmount);

					derivCalcSLRef.current = await window.calculateTradeAmount(priceNew, SL.current, riskAmount, multipliers.chosenDerivMultiplier);
					multiplierRef.current = multipliers.chosenMultiplier;
					derivMultiplierRef.current = multipliers.chosenDerivMultiplier;
					store.getState().setMultiplier(derivMultiplierRef.current);
					tradeAmountRef.current = derivCalcSLRef.current.tradeAmount;

					setTradeAmount(derivCalcSLRef.current.tradeAmount.toFixed(2));
					this.setQuantity(Number(derivCalcSLRef.current.tradeAmount).toFixed(2));

					slProfit.current = Number(riskAmountRef.current);
					this.setText(
						`SL: $${derivCalcSLRef.current.totalRisk.toFixed(2)} -- Pips: ${derivCalcSLRef.current.priceDifference.toFixed(2)} -- X: ${
							multipliers.chosenDerivMultiplier
						}`
					);

					if (tpLineRef.current) {
						TP.current = tpLineRef.current.getPrice();
						derivCalcTPRef.current = await calculateMultiplierProfit(
							priceNew,
							TP.current,
							tradeAmountRef.current,
							derivMultiplierRef.current
						);

						tpLineRef.current.setText(
							`TP: $${derivCalcTPRef.current.profit} -- Pips: ${derivCalcTPRef.current.pips} -- Payout: ${derivCalcTPRef.current.payout}`
						);
						tpLineRef.current.setQuantity(Number(derivCalcSLRef.current.tradeAmount).toFixed(2));
					}
				})
				.onCancel(function () {})
				.setText(
					`SL: $${derivCalcSLRef.current.totalRisk.toFixed(2)} -- Pips: ${pipsSL.current.toFixed(2)}  X: ${derivMultiplierRef.current}`
				)
				.setQuantity(Number(tradeAmount).toFixed(2))
				.setPrice(SL.current)
				.setLineColor("#cc2e3d")
				.setQuantityBorderColor("#cc2e3d")
				.setBodyTextColor("#cc2e3d")
				.setBodyBorderColor("#cc2e3d")
				.setBodyBackgroundColor("rgba(0, 0, 0, 0)")
				.setQuantityBackgroundColor("rgba(0, 0, 0, 0)")
				.setCancelButtonBackgroundColor("rgba(0, 0, 0, 0)")
				.setCancelButtonIconColor("#cc2e3d")
				.setCancelButtonBorderColor("#cc2e3d");
		}

		if (tpLineRef.current) {
			tpLineRef.current.remove();
			tpLineRef.current = null;
			store.getState().setSlTpButton(false);
		} else {
			store.getState().setSlTpButton(true);
			setPreStopLoss(null);
			tpLineRef.current = tvWidget
				.chart()
				.createOrderLine()
				.onMove(async function () {
					const priceNew = Number(lastBarRef.current.close);
					TP.current = tpLineRef.current.getPrice();

					console.log("TP LINE MOVE", TP.current);
					derivCalcTPRef.current = await calculateMultiplierProfit(
						priceNew,
						TP.current,
						tradeAmountRef.current,
						derivMultiplierRef.current
					);

					this.setText(
						`TP: $${derivCalcTPRef.current.profit} -- Pips: ${derivCalcTPRef.current.pips} -- Payout: ${derivCalcTPRef.current.payout}`
					);
				})
				.onCancel(function () {})
				.setText(`TP: $${derivCalcTPRef.current.profit} -- Pips: ${derivCalcTPRef.current.pips} -- Payout: ${derivCalcTPRef.current.payout}`)
				.setQuantity(Number(tradeAmount).toFixed(2))
				.setPrice(TP.current)
				.setLineColor("#01a79e")
				.setQuantityBorderColor("#01a79e")
				.setBodyBorderColor("#01a79e")
				.setBodyTextColor("#01a79e")
				.setBodyBackgroundColor("rgba(0, 0, 0, 0)")
				.setQuantityBackgroundColor("rgba(0, 0, 0, 0)")
				.setCancelButtonBackgroundColor("rgba(0, 0, 0, 0)")
				.setCancelButtonIconColor("#01a79e")
				.setCancelButtonBorderColor("#01a79e");

			tpLineRef.current.setQuantity(riskAmountRef.current);
		}
	};

	window.calculateTradeAmount = async (entrySpot, stopPrice, riskAmount, multiplier) => {
		const symbolInfo = await JSON.parse(localStorage.getItem("symbolInfo"));
		entrySpot = Number(entrySpot);
		stopPrice = Number(stopPrice);
		riskAmount = Number(riskAmount);
		multiplier = Number(multiplier);
		// console.log("CALCULATE TRADE AMOUNT", entrySpot, stopPrice, riskAmount, multiplier);

		const priceDifference = Math.abs(stopPrice - entrySpot);
		tradeAmountRef.current = riskAmount / ((priceDifference / entrySpot) * multiplier);
		pipsSL.current = priceDifference;

		store.getState().setTradeAmount(tradeAmountRef.current);
		store.getState().setSlPrice(stopPrice);

		const proposal = await window.callProposal(tradeAmountRef.current, symbolInfo.symbol, multiplier);
		if (proposal?.commission) {
			riskAmountComissionRef.current = Number(proposal.commission) || 0;
			riskAmountTotalRef.current = riskAmount + riskAmountComissionRef.current;
		}

		return {
			tradeAmount: tradeAmountRef.current,
			priceDifference,
			totalRisk: riskAmountTotalRef.current, //.toFixed(2),
		};
	};

	const calculateMultiplierProfit = async (entrySpot, exitSpot, tradeAmount, multiplier) => {
		entrySpot = Number(entrySpot);
		exitSpot = Number(exitSpot);
		tradeAmount = Number(tradeAmount);
		multiplier = Number(multiplier);
		console.log("CALCULATE MULTIPLIER PROFIT", entrySpot, exitSpot, tradeAmount, multiplier);

		const profitWithoutCommission = (Math.abs(exitSpot - entrySpot) / entrySpot) * tradeAmount * multiplier;
		const profit = profitWithoutCommission - riskAmountComissionRef.current;
		const payout = tradeAmount + profit;
		const pipDifference = Math.abs(exitSpot - entrySpot);

		tpProfit.current = profit;
		pipsTP.current = pipDifference;

		store.getState().setTpPrice(exitSpot);

		return {
			profit: profit.toFixed(2),
			payout: payout.toFixed(2),
			pips: pipDifference.toFixed(2),
		};
	};

	window.calcMultipliersOLD = async (symbolInfo, SL, priceNew) => {
		const derivMultiplier = symbolInfo.derivMultiplier;
		const multiplier2 = symbolInfo.multiplier2;
		const derivMultiplier2 = symbolInfo.derivMultiplier2;
		const multiplier3 = symbolInfo.multiplier3;
		const derivMultiplier3 = symbolInfo.derivMultiplier3;
		const multiplier4 = symbolInfo.multiplier4;
		const derivMultiplier4 = symbolInfo.derivMultiplier4;
		const multiplier5 = symbolInfo.multiplier5;
		const derivMultiplier5 = symbolInfo.derivMultiplier5;
		const stopOut = Number(symbolInfo.stopOut);
		const pipsSL = Math.abs(SL - priceNew);

		let chosenDerivMultiplier = derivMultiplier;
		let chosenMultiplier = 1;

		if (stopOut / multiplier2 > pipsSL) {
			chosenDerivMultiplier = derivMultiplier2;
			chosenMultiplier = multiplier2;
			// console.log("MULTIPLIER 2", chosenDerivMultiplier, chosenMultiplier);
		}
		if (stopOut / multiplier3 > pipsSL) {
			chosenDerivMultiplier = derivMultiplier3;
			chosenMultiplier = multiplier3;
			// console.log("MULTIPLIER 3", chosenDerivMultiplier, chosenMultiplier);
		}
		if (multiplier4 && stopOut / multiplier4 > pipsSL) {
			chosenDerivMultiplier = derivMultiplier4;
			chosenMultiplier = multiplier4;
			// console.log("MULTIPLIER 4", chosenDerivMultiplier, chosenMultiplier);
		}
		if (multiplier5 && stopOut / multiplier5 > pipsSL) {
			chosenDerivMultiplier = derivMultiplier5;
			chosenMultiplier = multiplier5;
			// console.log("MULTIPLIER 5", chosenDerivMultiplier, chosenMultiplier);
		}

		// console.log("MULTIPLIERS OLD", chosenDerivMultiplier, stopOut / chosenMultiplier);
		return {
			chosenDerivMultiplier,
			chosenMultiplier,
		};
	};

	window.calcMultipliers = async (symbolInfo, SL, priceNew) => {
		const derivMultiplier = symbolInfo.derivMultiplier;
		const symbol = symbolInfo.symbol;
		const multipliers = [1, symbolInfo.multiplier2, symbolInfo.multiplier3, symbolInfo.multiplier4, symbolInfo.multiplier5];
		const derivMultipliers = [
			derivMultiplier,
			symbolInfo.derivMultiplier2,
			symbolInfo.derivMultiplier3,
			symbolInfo.derivMultiplier4,
			symbolInfo.derivMultiplier5,
		];
		const stopOutBase = Number(symbolInfo.stopOut);
		const pipsSL = Math.abs(SL - priceNew);

		let chosenDerivMultiplier = derivMultiplier;
		let chosenMultiplier = 1;

		for (let i = 1; i < multipliers.length; i++) {
			const multiplier = multipliers[i];
			const derivMultiplierCurrent = derivMultipliers[i];

			if (!multiplier || !derivMultiplierCurrent) {
				continue;
			}

			// Calcular StopOut para este multiplicador usando la fórmula
			let stopOutForCurrentMultiplier = stopOutBase * (1 / multiplier);

			if (symbolInfo?.derivMultiplier5) {
				stopOutForCurrentMultiplier = stopOutForCurrentMultiplier * (1 - multiplier / 100);
			} else if (symbol.includes("CRASH") || symbol.includes("BOOM")) {
				if (derivMultiplierCurrent === 200) {
					stopOutForCurrentMultiplier = stopOutBase * 0.42; // 100% - 42%
				} else if (derivMultiplierCurrent === 300) {
					stopOutForCurrentMultiplier = stopOutBase * 0.22; // 100% - 23%
				} else if (derivMultiplierCurrent === 400) {
					stopOutForCurrentMultiplier = stopOutBase * 0.12; // 100% - 12%
				}
			}

			if (stopOutForCurrentMultiplier > pipsSL) {
				chosenDerivMultiplier = derivMultiplierCurrent;
				chosenMultiplier = multiplier;
			}
		}
		return {
			chosenDerivMultiplier,
			chosenMultiplier,
		};
	};

	const drawTrailingStop = async () => {
		const tvWidget = store.getState().chart;
		const symbolInfo = store.getState().symbolInfo;

		if (!symbolInfo || !lastBarRef.current) return;

		const currentPrice = Number(lastBarRef.current.close);

		for (const position of trailingPositions) {
			const contractId = position.contractId;
			const trailingDistance = Number(position.trailingStopDistance);

			let trailingStopPrice =
				position.trailingStopPrice !== undefined
					? position.trailingStopPrice
					: position.type === "Sell"
					? currentPrice + trailingDistance
					: currentPrice - trailingDistance;

			if (position.type === "Sell") {
				const difference = trailingStopPrice - currentPrice;
				if (difference > 0 && difference > trailingDistance) {
					trailingStopPrice = currentPrice + trailingDistance;
				}
			} else {
				const difference = currentPrice - trailingStopPrice;
				if (difference > 0 && difference > trailingDistance) {
					trailingStopPrice = currentPrice - trailingDistance;
				}
			}

			position.trailingStopPrice = trailingStopPrice;

			const profitWithoutCommission =
				(Math.abs(trailingStopPrice - Number(position.entryPrice)) / Number(position.entryPrice)) *
				Number(position.tradeAmount) *
				Number(position.derivMultiplier);
			const profit = profitWithoutCommission - (position.commission || 0);
			const pips = Math.abs(trailingStopPrice - Number(position.entryPrice));

			if (trailingStopLinesRef.current[contractId]) {
				trailingStopLinesRef.current[contractId]
					.setPrice(trailingStopPrice)
					.setText(`TrailingStop: $${profit.toFixed(2)} -- Pips: ${pips.toFixed(2)}`)
					.setTooltip(`Contract ID: ${contractId}`);
			} else {
				trailingStopLinesRef.current[contractId] = tvWidget
					.chart()
					.createOrderLine()
					.onMove(async function () {
						const newPrice = this.getPrice();
						const newDistance = Math.abs(currentPrice - newPrice);
						position.trailingStopDistance = newDistance;

						const newTrailingStopPrice = position.type === "Sell" ? currentPrice + newDistance : currentPrice - newDistance;
						position.trailingStopPrice = newTrailingStopPrice;

						const profitWithoutCommission =
							(Math.abs(Number(newPrice) - Number(position.entryPrice)) / Number(newPrice)) *
							Number(position.tradeAmount) *
							Number(position.derivMultiplier);
						const profit = profitWithoutCommission - (position.commission || 0);
						const pips = Math.abs(newPrice - Number(position.entryPrice));

						this.setText(`TS: ${profit.toFixed(2)} -- Pips: ${pips.toFixed(2)}`);
						activateTrailing(position, pips.toFixed(2));
					})
					.onCancel(function () {
						stopTrailingStopInterval(position);
					})
					.setPrice(trailingStopPrice)
					.setText(`TrailingStop: $${profit.toFixed(2)} -- Pips: ${pips.toFixed(2)}`)
					.setTooltip(`Contract ID: ${contractId}`)
					.setLineColor("#FFA500")
					.setQuantity(position.tradeAmount)
					.setLineColor("#FFA500")
					.setQuantityBorderColor("#FFA500")
					.setBodyTextColor("#FFA500")
					.setBodyBorderColor("#FFA500")
					.setBodyBackgroundColor("rgba(0, 0, 0, 0)")
					.setQuantityBackgroundColor("rgba(0, 0, 0, 0)")
					.setCancelButtonBackgroundColor("rgba(0, 0, 0, 0)")
					.setCancelButtonIconColor("#FFA500")
					.setCancelButtonBorderColor("#FFA500");
			}
		}
	};

	const startTrailingStopInterval0 = async (serverData) => {
		const position = serverData;

		if (!trailingPositions.some((p) => p.contractId === position.contractId)) {
			trailingPositions.push(position);
		}
		if (!trailingStopIntervalRef.current) {
			trailingStopIntervalRef.current = setInterval(() => {
				drawTrailingStop();
			}, 1000);
		}
	};

	window.startTrailingStopInterval = async (position) => {
		if (!trailingPositions.some((p) => p.contractId === position.contractId)) {
			const dif = Math.abs(Number(position.stopLoss) - Number(position.entryPrice)) / 2;

			position.trailingStopDistance = dif.toFixed(2);
			trailingPositions.push(position);
			activateTrailing(position, dif.toFixed(2));
		}

		if (!trailingStopIntervalRef.current) {
			trailingStopIntervalRef.current = setInterval(() => {
				drawTrailingStop();
			}, 1000);
		}
	};

	const stopTrailingStopInterval = (position) => {
		const index = trailingPositions.findIndex((p) => p.contractId === position.contractId);
		if (index !== -1) {
			const trailingPosition = trailingPositions[index];

			if (trailingPosition.trailingStopLine) {
				trailingPosition.trailingStopLine.remove();
				trailingPosition.trailingStopLine = null;
			}

			if (trailingStopLinesRef.current[position.contractId]) {
				trailingStopLinesRef.current[position.contractId].remove();
				delete trailingStopLinesRef.current[position.contractId];
			}

			trailingPositions.splice(index, 1);
		}

		if (trailingPositions.length === 0 && trailingStopIntervalRef.current) {
			clearInterval(trailingStopIntervalRef.current);
			trailingStopIntervalRef.current = null;
		}

		deActivateTrailing(position);
	};

	const activateTrailing = (position, distance) => {
		const ts = {
			position,
			distance,
			activate: true,
		};
		socket.emit("trailingStop", ts);
	};

	const deActivateTrailing = (position) => {
		const ts = {
			position,
			distance: 0,
			activate: false,
		};
		socket.emit("trailingStop", ts);
	};

	const drawBarrierLine = () => {
		const tvWidget = store.getState().chart;

		if (lastBarRef.current !== null && tvWidget) {
			const barrier = Number(barrierRef.current);
			const currentPrice = Number(lastBarRef.current.close);
			const newPrice = currentPrice + barrier;

			if (barrierLineRef.current) {
				barrierLineRef.current.setPrice(newPrice).setText(`Barrera`).setQuantity(barrier);
			} else {
				barrierLineRef.current = tvWidget
					.chart()
					.createOrderLine()
					.onMove(function () {
						const newLinePrice = this.getPrice();
						const currentPrice = lastBarRef.current.close;
						const newDistance = newLinePrice - currentPrice;
						updateBarrier(newDistance);
					})
					.setText(`Barrera`)
					.setQuantity(barrier)
					.setPrice(newPrice)
					.setLineColor("#4CAF50")
					.setBodyTextColor("#4CAF50")
					.setBodyBackgroundColor("rgba(0, 0, 0, 0)")
					.setQuantityBackgroundColor("rgba(0, 0, 0, 0)");
			}
		}
	};

	const startBarrierInterval = () => {
		if (!barrierIntervalRef.current) {
			barrierIntervalRef.current = setInterval(drawBarrierLine, 1000);
		}
	};

	const stopBarrierInterval = () => {
		if (barrierIntervalRef.current) {
			clearInterval(barrierIntervalRef.current);
			barrierIntervalRef.current = null;
		}
		if (barrierLineRef.current) {
			barrierLineRef.current.remove();
			barrierLineRef.current = null;
		}
	};

	const updateBarrier = async (distance) => {
		if (distance !== "" && distance !== "0" && distance !== "0." && !distance.startsWith("+") && !distance.startsWith("-")) {
			toast.error("La barrera debe comenzar con '+' o '-'");
			return;
		}
		setBarrier(distance);
		await localStorage.setItem("barrier", distance);
		barrierRef.current = distance;

		if (distance !== "" && distance !== "0" && distance !== "0.") {
			handleProposal();
		}
	};

	const handleContractType = (optType) => {
		console.log("optType", optType);
		if (optType === "riseFall" || optType === "accumulators") {
			stopBarrierInterval();
		}
		if (optType !== "accumulators") {
			console.log("! ACCUMULATORS");
			removeAccumLines();
		}
		setContractType(optType);
		contractTypeRef.current = optType;
		localStorage.setItem("optionType", optType);
		handleProposal();
	};

	const handleProposal = async () => {
		try {
			if (symbolInfoRef.current == null && sliderRef.current == null) {
				setTimeout(() => {
					handleProposal();
				}, 300);
			}
			let callMessage = {};
			let putMessage = {};

			// getSymbols();

			if (contractTypeRef.current === "riseFall") {
				callMessage = JSON.stringify({
					proposal: 1,
					amount: Number(riskAmountRef.current),
					basis: "stake",
					contract_type: "CALL",
					currency: "USD",
					duration: Number(sliderRef.current),
					duration_unit: "t",
					symbol: symbolInfoRef.current.symbol,
				});
			} else if (contractTypeRef.current === "higherLower") {
				const newValue = Math.max(5, sliderRef.current);
				setSliderValue(newValue);
				sliderRef.current = newValue;
				callMessage = JSON.stringify({
					proposal: 1,
					amount: Number(riskAmountRef.current),
					barrier: barrierRef.current,
					basis: "stake",
					contract_type: "CALL",
					currency: "USD",
					duration: Number(sliderRef.current),
					duration_unit: "t",
					symbol: symbolInfoRef.current.symbol,
				});
			} else if (contractTypeRef.current === "touchNoTouch") {
				callMessage = JSON.stringify({
					proposal: 1,
					amount: Number(riskAmountRef.current),
					barrier: barrierRef.current ? barrierRef.current : null,
					basis: "stake",
					contract_type: "ONETOUCH",
					currency: "USD",
					duration: Number(sliderRef.current),
					duration_unit: "t",
					symbol: symbolInfoRef.current.symbol,
				});
			} else if (contractTypeRef.current === "accumulators") {
				const proposalId = store.getState().proposalId;
				if (proposalId !== null) {
					derivWS.send(JSON.stringify({ forget: proposalId }));
					store.getState().setProposalId(null);
				}

				const newValue = Math.min(sliderRef.current, 5);
				await localStorage.setItem("sliderValue", newValue.toString());
				setSliderValue(newValue);
				sliderRef.current = Number(newValue);
				const rate = Number(newValue) / 100;

				callMessage = JSON.stringify({
					proposal: 1,
					amount: Number(riskAmountRef.current) === 0 ? 1 : Number(riskAmountRef.current),
					growth_rate: rate,
					basis: "stake",
					contract_type: "ACCU",
					currency: "USD",
					symbol: symbolInfoRef.current.symbol,
					subscribe: 1,
					passthrough: { accumulators: true },
				});

				callPayoutRef.current = "";
				setCallPayout(callPayoutRef.current);
				putPayoutRef.current = "";
				setPutPayout(putPayoutRef.current);
			}
			await derivWS.send(callMessage);
			const callProposal = await waitForMessageOfType("proposal");
			// console.log("CALL PROPOSAL 1", callProposal);

			if (contractTypeRef.current !== "accumulators") {
				const cp = Number(callProposal.proposal.payout) - Number(riskAmountRef.current);
				callPayoutRef.current = cp.toFixed(2);
				console.log("CALL PAYOUT", callPayoutRef.current);
				setCallPayout(callPayoutRef.current);
			}

			if (contractTypeRef.current !== "accumulators") {
				setTimeout(async () => {
					if (contractTypeRef.current === "riseFall") {
						putMessage = JSON.stringify({
							proposal: 1,
							amount: Number(riskAmountRef.current),
							basis: "stake",
							contract_type: "PUT",
							currency: "USD",
							duration: Number(sliderRef.current),
							duration_unit: "t",
							symbol: symbolInfoRef.current.symbol,
						});
					} else if (contractTypeRef.current === "higherLower") {
						putMessage = JSON.stringify({
							proposal: 1,
							amount: Number(riskAmountRef.current),
							barrier: barrierRef.current,
							basis: "stake",
							contract_type: "PUT",
							currency: "USD",
							duration: Number(sliderRef.current),
							duration_unit: "t",
							symbol: symbolInfoRef.current.symbol,
						});
					} else if (contractTypeRef.current === "touchNoTouch") {
						putMessage = JSON.stringify({
							proposal: 1,
							amount: Number(riskAmountRef.current),
							barrier: barrierRef.current ? barrierRef.current : null,
							basis: "stake",
							contract_type: "NOTOUCH",
							currency: "USD",
							duration: Number(sliderRef.current),
							duration_unit: "t",
							symbol: symbolInfoRef.current.symbol,
						});
					}
					await derivWS.send(putMessage);
					const putProposal = await waitForMessageOfType("proposal");
					// console.log("CALL PROPOSAL 2", callProposal);

					if (putProposal.error) {
						console.log(translations["Error:"], translations[putProposal.error.message] || putProposal.error.message);
					} else {
						if (contractTypeRef.current !== "accumulators") {
							const pp = Number(putProposal.proposal.payout) - Number(riskAmountRef.current);
							putPayoutRef.current = pp.toFixed(2);
							console.log("PUT PAYOUT", putPayoutRef.current);
							setPutPayout(putPayoutRef.current);
						}
					}
				}, 500);
			}
		} catch (error) {
			console.log("HANDLW PROPOSAL ERROR", error);
		}
	};

	useEffect(() => {
		const handleBuyOptions = (event) => {
			const type = event.detail;
			buyOptions(type);
		};
		window.addEventListener("buyOptions", handleBuyOptions);

		const handleCalculateAmount = (event) => {
			const percent = event.detail;
			// store.getState().setRiskAmount(Number(percent).toFixed(2));
			store.getState().setRiskAmount(Number(percent).toFixed(2));
			riskAmountRef.current = Number(percent).toFixed(2);
		};
		window.addEventListener("store.getState().setRiskAmount", handleCalculateAmount);

		const resetCalculateAmount = (event) => {
			store.getState().setRiskAmount(Number(riskAmount).toFixed(2));

			riskAmountRef.current = Number(riskAmount).toFixed(2);
		};
		window.addEventListener("resetRiskAmount", resetCalculateAmount);

		return () => {
			window.removeEventListener("buyOptions", handleBuyOptions);
			window.removeEventListener("store.getState().setRiskAmount", handleCalculateAmount);
			window.removeEventListener("resetRiskAmount", resetCalculateAmount);
		};
	}, []);

	const toggleLock = () => {
		setIsLocked(!isLocked);
		setDragging(!isLocked);
	};

	window.handleSLTPButtonClick = async () => {
		const cc = await getLastCloses(60, 50);
		const retCandlesStorage = localStorage.getItem("retCandlesQty");
		const x = { target: { value: retCandlesStorage } };
		store.getState().setProfitBoost(false);

		setCustomCandles(cc);
		if (slRetCandlesRef.current) {
			slRetCandlesRef.current.remove();
			slRetCandlesRef.current = null;
			store.getState().setRetCandles(false);
		} else {
			store.getState().setRetCandles(true);
			setTimeout(() => {
				window.handleInputRetCandles(x);
			}, 1000);
		}

		if (tpRetCandlesRef.current) {
			tpRetCandlesRef.current.remove();
			tpRetCandlesRef.current = null;
			store.getState().setRetCandles(false);
		} else {
			store.getState().setRetCandles(true);
			setTimeout(() => {
				window.handleInputRetCandles(x);
			}, 1000);
		}
	};

	window.handleInputRetCandles = async (e) => {
		if (!e || !e.target) {
			console.error("EVENTO O TARGET DEL EVENTO NO DEFINIDO");
			return;
		}

		console.log("RETT CANDLES", e.target.value);

		// setRetCandlesQty(e.target.value);
		store.getState().setRetCandlesQty(e.target.value);
		localStorage.setItem("retCandleQty", e.target.value);

		const lastTenCandles = customCandles.slice(-20);
		const bullishCandles = lastTenCandles.filter((candle) => candle.close > candle.open);
		const bearishCandles = lastTenCandles.filter((candle) => candle.close < candle.open);
		let relevantCandles = bullishCandles.length > bearishCandles.length ? bullishCandles : bearishCandles;
		const openCloseDifferences = relevantCandles.map((candle) => {
			return Math.abs(candle.close - candle.open);
		});

		const averageOpenCloseDifference = openCloseDifferences.reduce((sum, diff) => sum + diff, 0) / openCloseDifferences.length;
		const stopLossDistance = averageOpenCloseDifference * Number(e.target.value);
		const currentPrice = Number(lastBarRef.current.close);
		const displayName = symbolInfoRef.current.displayName;
		let newStopLossPrice = null;
		let newTakeProfitPrice = null;

		if (displayName.startsWith("Boom")) {
			newStopLossPrice = currentPrice - stopLossDistance;
			newTakeProfitPrice = currentPrice + stopLossDistance;
		} else {
			newStopLossPrice = currentPrice + stopLossDistance;
			newTakeProfitPrice = currentPrice - stopLossDistance;
		}

		const symbolInfo = JSON.parse(localStorage.getItem("symbolInfo"));
		const multipliers = await window.calcMultipliers(symbolInfo, newStopLossPrice, currentPrice);

		const pipsSL = Math.abs(newStopLossPrice - currentPrice);

		let chosenDerivMultiplier = multipliers.chosenDerivMultiplier;
		let chosenMultiplier = multipliers.chosenMultiplier;

		console.log("<ULTIPLIER>", chosenMultiplier, chosenDerivMultiplier);
		derivCalcSLRef.current = await window.calculateTradeAmount(currentPrice, newStopLossPrice, riskAmount, chosenDerivMultiplier);

		multiplierRef.current = chosenMultiplier;
		derivMultiplierRef.current = chosenDerivMultiplier;
		store.getState().setMultiplier(derivMultiplierRef.current);
		tradeAmountRef.current = derivCalcSLRef.current.tradeAmount;

		setTradeAmount(derivCalcSLRef.current.tradeAmount.toFixed(2));
		slProfit.current = Number(riskAmountRef.current);

		derivCalcTPRef.current = await calculateMultiplierProfit(currentPrice, newTakeProfitPrice, tradeAmountRef.current, chosenDerivMultiplier);

		if (slRetCandlesRef.current) {
			slRetCandlesRef.current.setPrice(newStopLossPrice);
			slRetCandlesRef.current.setText(`SL: ${derivCalcSLRef.current.totalRisk} -- Pips: ${pipsSL.toFixed(2)} X: ${chosenDerivMultiplier}`);
			slRetCandlesRef.current.setQuantity(tradeAmountRef.current.toFixed(2));
		} else {
			slRetCandlesRef.current = tvWidget
				.chart()
				.createOrderLine()
				.setText(`SL: ${derivCalcSLRef.current.totalRisk} -- Pips: ${pipsSL.toFixed(2)} X: ${chosenDerivMultiplier}`)
				.setQuantity(tradeAmountRef.current.toFixed(2))
				.setPrice(newStopLossPrice.toFixed(2))
				.setLineColor("#cc2e3d")
				.setQuantityBorderColor("#cc2e3d")
				.setBodyTextColor("#cc2e3d")
				.setBodyBorderColor("#cc2e3d")
				.setBodyBackgroundColor("rgba(0, 0, 0, 0)")
				.setQuantityBackgroundColor("rgba(0, 0, 0, 0)")
				.setCancelButtonBackgroundColor("rgba(0, 0, 0, 0)")
				.setCancelButtonIconColor("#cc2e3d")
				.setCancelButtonBorderColor("#cc2e3d");
		}

		if (tpRetCandlesRef.current) {
			tpRetCandlesRef.current.setPrice(newTakeProfitPrice);
			tpRetCandlesRef.current.setText(
				`TP: $${derivCalcTPRef.current.profit} -- Pips: ${derivCalcTPRef.current.pips} -- Payout: ${derivCalcTPRef.current.payout}`
			);
			tpRetCandlesRef.current.setQuantity(tradeAmountRef.current.toFixed(2));
		} else {
			tpRetCandlesRef.current = tvWidget
				.chart()
				.createOrderLine()
				.onMove(async function () {
					const priceNew = Number(lastBarRef.current.close);
					TP.current = this.getPrice();

					derivCalcTPRef.current = await calculateMultiplierProfit(
						TP.current,
						priceNew,
						tradeAmountRef.current,
						derivMultiplierRef.current
					);

					this.setText(
						`TP: $${derivCalcTPRef.current.profit} -- Pips: ${derivCalcTPRef.current.pips} -- Payout: ${derivCalcTPRef.current.payout}`
					);
				})
				.setText(`TP: $${derivCalcTPRef.current.profit} -- Pips: ${derivCalcTPRef.current.pips} -- Payout: ${derivCalcTPRef.current.payout}`)
				.setQuantity(tradeAmountRef.current.toFixed(2))
				.setPrice(newTakeProfitPrice.toFixed(2))
				.setLineColor("#01a79e")
				.setQuantityBorderColor("#01a79e")
				.setBodyBorderColor("#01a79e")
				.setBodyTextColor("#01a79e")
				.setBodyBackgroundColor("rgba(0, 0, 0, 0)")
				.setQuantityBackgroundColor("rgba(0, 0, 0, 0)")
				.setCancelButtonBackgroundColor("rgba(0, 0, 0, 0)")
				.setCancelButtonIconColor("#01a79e")
				.setCancelButtonBorderColor("#01a79e");
		}
	};

	window.handleRiskButton = async (e) => {
		const value = e.target.value.replace(",", ".");
		if (/^\d*\.?\d*$/.test(value)) {
			store.getState().setRiskAmount(value);
			riskAmountRef.current = value;
			store.getState().setRiskAmount(Number(value).toFixed(2));
			localStorage.setItem("riskAmount", value);
			console.log("RISK AMOUNT", value);
			if (slLineRef.current) {
				slLineRef.current.setText(`SL: ${riskAmountRef.current} -- Pips: ${pipsSL.current.toFixed(2)}`);
			}
		}
	};

	window.handleManualSL = (sl) => {
		slLineRef.current.setPrice(sl);
	};

	window.handleManualTP = (tp) => {
		tpLineRef.current.setPrice(tp);
	};

	const handleRadialMenuClick = async (action) => {
		const posKey = `position_${modifingPosRef.current.contractId}`;

		switch (action) {
			case "tp":
				if (!existingLinesRef.current[`takeProfit_${modifingPosRef.current.contractId}`]) {
					existingLinesRef.current[posKey].setQuantityBackgroundColor("#4599d9");
					window.createSLTPLine(Number(modifingPosRef.current.contractId), "takeProfit", modifingPosRef.current);
				}
				break;
			case "sl":
				if (!existingLinesRef.current[`stopLoss_${modifingPosRef.current.contractId}`]) {
					existingLinesRef.current[posKey].setQuantityBackgroundColor("#4599d9");
					window.createSLTPLine(Number(modifingPosRef.current.contractId), "stopLoss", modifingPosRef.current);
				}
				break;
			case "ts":
				window.startTrailingStopInterval(modifingPosRef.current);
				break;
			case "be":
				window.setBreakEven();
				break;
			default:
				break;
		}
		store.getState().setShowPositionMenu(!showPositionMenu);
	};

	return (
		<>
			<Menu
				centerX={positionMenu.x}
				centerY={positionMenu.y}
				innerRadius={25}
				outerRadius={68}
				show={showPositionMenu}
				animation={["fade", "scale"]}
				animationTimeout={150}
				animateSubMenuChange={true}
				drawBackground
				theme={"dark"}
				fontSize={12}>
				<MenuItem onItemClick={() => handleRadialMenuClick("tp")} data="tp" style={{ fontSize: "8px", fontFamily: "'Orbitron', sans-serif" }}>
					Take Profit
				</MenuItem>
				<MenuItem onItemClick={() => handleRadialMenuClick("sl")} data="sl" style={{ fontSize: "8px", fontFamily: "'Orbitron', sans-serif" }}>
					Stop Loss
				</MenuItem>
				<MenuItem
					onItemClick={() => handleRadialMenuClick("ts")}
					data="ts"
					style={{ fontSize: "8px", fontFamily: "'Orbitron', sans-serif", color: trailingStop ? "#00ff00" : "inherit" }}>
					Trailing Stop
				</MenuItem>
				<MenuItem onItemClick={() => handleRadialMenuClick("be")} data="be" style={{ fontSize: "8px", fontFamily: "'Orbitron', sans-serif" }}>
					Break Even
				</MenuItem>
			</Menu>
			{showPanel && (
				<ThemeProvider theme={theme}>
					{chartType === "Multiplicadores" && (
						<Rnd
							position={position}
							onDragStop={(e, d) => {
								const newPosition = { x: d.x, y: d.y };
								setPosition(newPosition);
								localStorage.setItem("windowPosition", JSON.stringify(newPosition)); // Guarda en localStorage
								toggleLock();
							}}
							enableUserSelectHack={false}
							enableResizing={false}
							bounds="window"
							onDoubleClick={toggleLock}
							disableDragging={dragging}>
							<div
								onClick={toggleLock}
								style={{
									position: "absolute",
									top: "-5px",
									right: "-15px",
									cursor: "pointer",
									zIndex: 1000,
									color: "#fff", // Change color as needed
								}}>
								{isLocked ? <LockOutlinedIcon style={{ fontSize: "16px" }} /> : <LockOpenOutlinedIcon style={{ fontSize: "16px" }} />}
							</div>

							{positionType === "multipliers" && (
								<Card
									sx={{
										backgroundColor: "rgba(23, 27, 38, 0.9)",
										height: "fit-content",
										width: "17rem",
										display: "flex",
										flexDirection: "column",
										justifyContent: "center",
										alignItems: "center",
										gap: "0.5rem",
										padding: "5px",
										borderRadius: "10px",
										boxShadow:
											selectedAccount && selectedAccount.account && selectedAccount.account.startsWith("CR")
												? "0 0 5px #4599d9, 0 0 30px #4599d9"
												: "0 0 5px#cc2e3d, 0 0 30px#cc2e3d",
										borderWidth: "1px",
										borderStyle: "solid",
										borderColor:
											selectedAccount && selectedAccount.account && selectedAccount.account.startsWith("CR")
												? "#4599d9"
												: "#cc2e3d",
									}}>
									<Box
										sx={{
											display: "flex",
											flexDirection: "row",
											justifyContent: "center",
											alignItems: "center",
											gap: "0.5rem",
										}}>
										<MUIButton
											variant="contained"
											sx={{
												height: "1.8rem",
												width: retCandles ? "2.5rem" : "4.5rem",
												fontWeight: "bold",
												fontFamily: "'Orbitron', sans-serif",
												fontSize: "0.8rem",
												color: "#ffffff",
												backgroundColor: "#cc2e3d", // Changed from error color to a custom#cc2e3d
												textShadow: "0 0 5px #fff",
												boxShadow: "0 0 10px#cc2e3d, 0 0 20px#cc2e3d",
												"&:hover": {
													backgroundColor: "#d93d0d", // Darker shade for hover effect
												},
												"@media (max-width: 768px)": {
													fontSize: "10px",
													height: "1.5rem",
													width: "3rem",
												},
											}}
											onClick={() => windows.buyMultUpMultDn("MULTDOWN")}>
											Sell
										</MUIButton>

										<TextField
											variant="outlined"
											label="Riesgo"
											value={riskAmount}
											onChange={(e) => {
												window.handleRiskButton(e);
											}}
											// disabled={retCandles}
											sx={{
												width: "4rem",
												"& .MuiOutlinedInput-root": {
													"& input": {
														color: "#ffffff",
														fontSize: "0.75rem",
														padding: "6px 14px",
													},
													"& fieldset": {
														borderColor: "#4599d9",
													},
													"&:hover fieldset": {
														borderColor: "#cc2e3d",
													},
												},
												"& .MuiInputLabel-root": {
													color: "#ffffff",
													fontSize: "0.75rem",
												},
											}}
										/>
										{retCandles && (
											<TextField
												variant="outlined"
												fullWidth
												label={!retCandles ? "Ret Candles" : "Porcentaje (%)"}
												value={!retCandles ? retCandlesQty : inputValue}
												onChange={(t) => {
													if (!retCandles) {
														window.handleInputRetCandles(t);
													} else {
														window.handleInputChange(t);
													}
												}}
												sx={{
													width: "5rem",
													margin: "0.2rem",
													input: {
														color: "#ffffff",
														fontSize: "0.75rem",
														padding: "6px 14px",
													},
													"& .MuiOutlinedInput-root": {
														"& fieldset": {
															borderColor: "#4599d9",
														},
														"&:hover fieldset": {
															borderColor: "#cc2e3d",
														},
													},
													"& .MuiInputLabel-root": {
														color: "#ffffff",
														fontSize: "0.75rem",
													},
												}}
											/>
										)}
										<MUIButton
											variant="contained"
											sx={{
												height: "1.8rem",
												width: retCandles ? "2.5rem" : "4.5rem",
												fontWeight: "bold",
												fontFamily: "'Orbitron', sans-serif",
												fontSize: "0.8rem",
												color: "#ffffff",
												backgroundColor: "#4599d9",
												textShadow: "0 0 5px #fff",
												boxShadow: "0 0 5px #4599d9, 0 0 10px #4599d9",
												"&:hover": {
													backgroundColor: "#4599d9",
												},
												"@media (max-width: 768px)": {
													fontSize: "0.8rem",
													height: "1.5rem",
													width: "3rem",
												},
											}}
											onClick={() => window.buyMultUpMultDn("MULTUP")}>
											Buy
										</MUIButton>
									</Box>

									<Box
										sx={{
											display: "flex",
											flexDirection: "row",
											justifyContent: "center",
											alignItems: "center",
											width: "100%",
										}}>
										{/* <MUIButton
											variant="contained"
											sx={{
												width: "2rem",
												padding: "0.2rem",
												fontSize: "0.6rem",
												fontWeight: "bold",
												fontFamily: "'Orbitron', sans-serif",
												color: "#ffffff",
												margin: "0.1rem",
												lineHeight: "1",
												backgroundColor: trailingStop ? "#4599d9" : "#808080", // Cambiar color si está activo
												textShadow: "0 0 1px #fff",
												boxShadow: trailingStop ? "0 0 5px #4599d9, 0 0 10px #4599d9" : "0 0 5px #808080, 0 0 10px #808080",
												"&:hover": {
													backgroundColor: slTpButtonColor === "#808080" ? "#696969" : "#01a79e",
													boxShadow: "0 0 15px #4599d9, 0 0 30px #4599d9",
												},
											}}
											onClick={() => window.startTrailingStopInterval(!trailingStop)}>
											TLN ST
										</MUIButton>

										<MUIButton
											variant="contained"
											sx={{
												width: "2rem",
												padding: "0.2rem",
												fontSize: "0.6rem",
												fontWeight: "bold",
												fontFamily: "'Orbitron', sans-serif",
												color: "#ffffff",
												margin: "0.2rem",
												lineHeight: "1",
												backgroundColor: slTpButtonColor,
												textShadow: "0 0 1px #fff",
												boxShadow:
													slTpButtonColor === "#808080"
														? "0 0 5px #808080, 0 0 10px #808080"
														: "0 0 5px #4599d9, 0 0 10px #4599d9",
												"&:hover": {
													backgroundColor: slTpButtonColor === "#808080" ? "#696969" : "#01a79e",
													boxShadow: "0 0 15px #4599d9, 0 0 30px #4599d9",
												},
											}}
											onClick={() => window.setBreakEven()()}>
											BRK EVN
										</MUIButton> */}

										<TextField
											variant="outlined"
											fullWidth
											label={retCandles ? "Ret Candles" : "Porcentaje (%)"}
											value={retCandles ? retCandlesQty : inputValue}
											onChange={(t) => {
												if (retCandles) {
													window.handleInputRetCandles(t);
												} else {
													window.handleInputChange(t);
												}
											}}
											sx={{
												width: "5rem",
												margin: "0.2rem",
												input: {
													color: "#ffffff",
													fontSize: "0.75rem",
													padding: "6px 14px",
												},
												"& .MuiOutlinedInput-root": {
													"& fieldset": {
														borderColor: "#4599d9",
													},
													"&:hover fieldset": {
														borderColor: "#cc2e3d",
													},
												},
												"& .MuiInputLabel-root": {
													color: "#ffffff",
													fontSize: "0.75rem",
												},
											}}
										/>

										<MUIButton
											variant="contained"
											sx={{
												width: "2.5rem",
												padding: "0.2rem",
												fontSize: "0.55rem",
												fontWeight: "bold",
												fontFamily: "'Orbitron', sans-serif",
												color: "#ffffff",
												margin: "0.2rem",
												lineHeight: "1",
												height: "2rem",
												backgroundColor: retCandles ? "#4599d9" : slTpButtonColor, // Cambiar color si está activo
												textShadow: "0 0 1px #fff",
												boxShadow: retCandles
													? "0 0 5px #4599d9, 0 0 10px #4599d9"
													: slTpButtonColor === "#808080"
													? "0 0 5px #808080, 0 0 10px #808080"
													: "0 0 5px #4599d9, 0 0 10px #4599d9",
												"&:hover": {
													backgroundColor: slTpButtonColor === "#808080" ? "#696969" : "#01a79e",
													boxShadow: "0 0 15px #4599d9, 0 0 30px #4599d9",
												},
												"&:active": {
													backgroundColor: "#4599d9", // Color azul cuando el botón está activo
													boxShadow: "0 0 5px #4599d9, 0 0 10px #4599d9",
												},
											}}
											onClick={async () => {
												window.handleSLTPButtonClick();
											}}>
											Crash Boom
										</MUIButton>

										<MUIButton
											variant="contained"
											sx={{
												width: "2.5rem",
												padding: "0.2rem",
												fontSize: "0.55rem",
												fontWeight: "bold",
												fontFamily: "'Orbitron', sans-serif",
												color: "#ffffff",
												margin: "0.1rem",
												lineHeight: "1",
												height: "2rem",
												backgroundColor: slTpButton ? "#4599d9" : slTpButtonColor, // Cambiar color si está presionado
												textShadow: "0 0 1px #fff",
												boxShadow: slTpButton
													? "0 0 5px #4599d9, 0 0 10px #4599d9"
													: slTpButtonColor === "#808080"
													? "0 0 5px #808080, 0 0 10px #808080"
													: "0 0 5px #4599d9, 0 0 10px #4599d9",
												"&:hover": {
													backgroundColor: slTpButtonColor === "#808080" ? "#696969" : "#01a79e",
													boxShadow: "0 0 15px #4599d9, 0 0 30px #4599d9",
												},
												"&:active": {
													backgroundColor: "#4599d9", // Color azul cuando el botón está activo
													boxShadow: "0 0 5px #4599d9, 0 0 10px #4599d9",
												},
											}}
											onClick={() => window.drawSLTPLines()}>
											SL/TP
										</MUIButton>
									</Box>
								</Card>
							)}
						</Rnd>
					)}

					{chartType === "Opciones" && (
						<Rnd
							position={position}
							onDragStop={(e, d) => {
								const newPosition = { x: d.x, y: d.y };
								setPosition(newPosition);
								localStorage.setItem("windowPosition", JSON.stringify(newPosition));
								toggleLock();
							}}
							onDoubleClick={toggleLock}
							enableUserSelectHack={false}
							enableResizing={false}
							bounds="window"
							disableDragging={dragging}>
							<div
								onClick={toggleLock}
								style={{
									position: "absolute",
									top: "25px",
									right: "15px",
									cursor: "pointer",
									zIndex: 1000,
									color: "#fff", // Change color as needed
								}}>
								{isLocked ? <LockOutlinedIcon style={{ fontSize: "16px" }} /> : <LockOpenOutlinedIcon style={{ fontSize: "16px" }} />}
							</div>
							<Box sx={{ padding: "2rem", backgroundColor: "transparent" }}>
								<Box
									sx={{
										backgroundColor: "rgba(23, 27, 38, 0.9)",
										height: "fit-content",
										width: "23rem",
										display: "flex",
										flexDirection: "column",
										justifyContent: "center",
										alignItems: "center",
										gap: "0.5rem",
										padding: "5px",
										borderRadius: "10px",
										boxShadow:
											selectedAccount && selectedAccount.account && selectedAccount.account.startsWith("CR")
												? "0 0 5px #4599d9, 0 0 10px #4599d9"
												: "0 0 5px#cc2e3d, 0 0 10px#cc2e3d",
										borderWidth: "1px",
										borderStyle: "solid",
										borderColor:
											selectedAccount && selectedAccount.account && selectedAccount.account.startsWith("CR")
												? "#4599d9"
												: "#cc2e3d",
									}}>
									<Box
										sx={{
											display: "flex",
											flexDirection: "row",
											justifyContent: "center",
											alignItems: "center",
											gap: "0.5rem",
										}}>
										<MUIButton
											variant="contained"
											sx={{
												height: "2.2rem",
												width: "5.5rem",
												fontWeight: "bold",
												fontSize: "0.8rem",
												fontFamily: "'Orbitron', sans-serif",
												color: "#ffffff",
												backgroundColor: "#4599d9",
												textShadow: "0 0 5px #fff",
												boxShadow: "0 0 10px #4599d9, 0 0 20px #4599d9",
												"&:hover": {
													backgroundColor: "#4599d9", // Darker shade for hover effect
												},
												"@media (max-width: 768px)": {
													fontSize: "10px",
													height: "1.5rem",
													width: "3rem",
												},
											}}
											onClick={() => {
												let action = null;
												contractType === "riseFall"
													? (action = "CALL")
													: contractType === "higherLower"
													? (action = "CALL")
													: contractType === "touchNoTouch"
													? (action = "ONETOUCH")
													: contractType === "accumulators"
													? (action = "ACCU")
													: (action = null);
												buyOptions(action);
											}}>
											{contractType === "riseFall"
												? "RISE"
												: contractType === "higherLower"
												? "HIGHER"
												: contractType === "touchNoTouch"
												? "TOUCH"
												: contractType === "accumulators"
												? "COMPRAR"
												: ""}
											<br />${callPayoutRef.current}
										</MUIButton>

										<TextField
											variant="outlined"
											label="Riesgo"
											value={riskAmount}
											onChange={(e) => {
												const value = e.target.value.replace(",", ".");
												if (/^\d*\.?\d*$/.test(value)) {
													store.getState().setRiskAmount(value);
													handleProposal();
													riskAmountRef.current = value;
													store.getState().setRiskAmount(Number(value).toFixed(2));
													localStorage.setItem("riskAmount", value);
													if (slLineRef.current) {
														slLineRef.current.setText(
															`SL: ${riskAmountRef.current} -- Pips: ${pipsSL.current.toFixed(2)}`
														);
													}
												}
											}}
											// disabled={retCandles}
											sx={{
												width: "4rem",
												"& .MuiOutlinedInput-root": {
													"& input": {
														color: "#ffffff",
														fontSize: "0.75rem",
														padding: "6px 14px",
													},
													"& fieldset": {
														borderColor: "#4599d9",
													},
													"&:hover fieldset": {
														borderColor: "#cc2e3d",
													},
												},
												"& .MuiInputLabel-root": {
													color: "#ffffff",
													fontSize: "0.75rem",
												},
											}}
										/>
										<TextField
											variant="outlined"
											fullWidth
											label="Porcentaje (%)"
											value={retCandles ? retCandlesQty : inputValue}
											onChange={(t) => {
												if (retCandles) {
													window.handleInputRetCandles(t);
												} else {
													window.handleInputChange(t);
												}
											}}
											sx={{
												width: "5rem",
												margin: "0.2rem",
												input: {
													color: "#ffffff",
													fontSize: "0.75rem",
													padding: "6px 14px",
												},
												"& .MuiOutlinedInput-root": {
													"& fieldset": {
														borderColor: "#4599d9",
													},
													"&:hover fieldset": {
														borderColor: "#cc2e3d",
													},
												},
												"& .MuiInputLabel-root": {
													color: "#ffffff",
													fontSize: "0.75rem",
												},
											}}
										/>

										<MUIButton
											variant="contained"
											sx={{
												height: "2.2rem",
												width: "5.5rem",
												fontWeight: "bold",
												fontSize: "0.8rem",
												fontFamily: "'Orbitron', sans-serif",
												color: "#ffffff",
												backgroundColor: "#cc2e3d",
												textShadow: "0 0 5px #fff",
												boxShadow: "0 0 5px#cc2e3d, 0 0 10px#cc2e3d",
												"&:hover": {
													backgroundColor: "#cc2e3d",
												},
												"@media (max-width: 768px)": {
													fontSize: "0.8rem",
													height: "1.5rem",
													width: "3rem",
												},
											}}
											onClick={() => {
												let action = null;
												contractType === "riseFall"
													? (action = "PUT")
													: contractType === "higherLower"
													? (action = "PUT")
													: contractType === "touchNoTouch"
													? (action = "NOTOUCH")
													: contractType === "accumulators"
													? (action = "ACCUSELL")
													: (action = null);
												buyOptions(action);
											}}>
											{contractType === "riseFall"
												? "FALL"
												: contractType === "higherLower"
												? "LOWER"
												: contractType === "touchNoTouch"
												? "NOTOUCH"
												: contractType === "accumulators"
												? "VENDER"
												: ""}
											<br />${putPayoutRef.current}
										</MUIButton>
									</Box>

									<Box
										sx={{
											display: "flex",
											flexDirection: "row",
											justifyContent: "center",
											alignItems: "center",
											width: "100%",
										}}>
										<Slider
											aria-label="Discrete slider"
											defaultValue={0}
											valueLabelDisplay="auto"
											step={1}
											marks
											min={contractTypeRef.current === "higherLower" ? 5 : 0}
											max={contractTypeRef.current === "accumulators" ? 5 : 10}
											value={sliderValue}
											onChange={async (e, newValue) => {
												if (contractTypeRef.current === "accumulators" && newValue === 0) {
													toast.error("El valor mínimo debe ser 1", {
														position: "top-right",
														autoClose: 2000,
														hideProgressBar: false,
														closeOnClick: true,
														pauseOnHover: true,
														draggable: true,
														theme: "dark",
													});
													setSliderValue(Number(sliderRef.current));
													return;
												}
												await localStorage.setItem("sliderValue", newValue.toString());
												setSliderValue(Number(newValue));
												sliderRef.current = newValue;
												handleProposal();
											}}
											sx={{
												width: "200px",
												color: "#4599d9",
												marginRight: "2rem",
												"& .MuiSlider-thumb": {
													backgroundColor: "#ffffff",
													color: "#4599d9",
													width: "25px",
													height: "25px",
													display: "flex",
													justifyContent: "center",
													alignItems: "center",
													"&::after": {
														content: `"${sliderValue}"`,
														color: "#4599d9",
														fontSize: "16px",
														fontWeight: "bold",
														paddingTop: "0.6rem",
														paddingLeft: "1rem",
													},
												},
												"& .MuiSlider-valueLabel": {
													display: "none",
												},
												"& .MuiSlider-mark": {
													backgroundColor: "#ffffff",
												},
											}}
										/>

										{contractType !== "riseFall" && (
											<TextField
												variant="outlined"
												fullWidth
												label={contractTypeRef.current === "accumulators" ? "Profit Ticks" : "Barrera"}
												value={contractTypeRef.current === "accumulators" ? profitTick : barrier}
												onChange={async (t) => {
													const inputValue = t.target.value;
													if (contractTypeRef.current === "accumulators") {
														await localStorage.setItem("profitTick", inputValue);
														setProfitTick(inputValue);
													} else {
														updateBarrier(inputValue);
													}
												}}
												sx={{
													width: "5rem",
													margin: "0.2rem",
													input: {
														color: "#ffffff",
														fontSize: "0.75rem",
														padding: "6px 14px",
													},
													"& .MuiOutlinedInput-root": {
														"& fieldset": {
															borderColor: "#4599d9",
														},
														"&:hover fieldset": {
															borderColor: "#cc2e3d",
														},
													},
													"& .MuiInputLabel-root": {
														color: "#ffffff",
														fontSize: "0.75rem",
													},
												}}
											/>
										)}
									</Box>
								</Box>

								<Box
									sx={{
										display: "flex",
										flexDirection: "row",
										justifyContent: "center",
										alignItems: "center",
										width: "100%",
										paddingTop: "0.4rem",
									}}>
									<MUIButton
										variant="contained"
										sx={{
											width: "3.5rem",
											padding: "0.2rem",
											fontSize: "0.7rem",
											fontWeight: "bold",
											fontFamily: "'Orbitron', sans-serif",
											color: "#ffffff",
											margin: "0.2rem",
											lineHeight: "1",
											height: "2rem",
											backgroundColor: contractType === "riseFall" ? "#4599d9" : slTpButtonColor,
											textShadow: "0 0 1px #fff",
											boxShadow:
												contractType === "riseFall"
													? "0 0 5px #4599d9, 0 0 10px #4599d9"
													: slTpButtonColor === "#808080"
													? "0 0 5px #808080, 0 0 10px #808080"
													: "0 0 5px #4599d9, 0 0 10px #4599d9",
											"&:hover": {
												backgroundColor: slTpButtonColor === "#808080" ? "#696969" : "#01a79e",
												boxShadow: "0 0 15px #4599d9, 0 0 30px #4599d9",
											},
											"&:active": {
												backgroundColor: "#4599d9", // Color azul cuando el botón está activo
												boxShadow: "0 0 5px #4599d9, 0 0 10px #4599d9",
											},
										}}
										onClick={async () => {
											handleContractType("riseFall");
										}}>
										RISE FALL
									</MUIButton>
									<MUIButton
										variant="contained"
										sx={{
											width: "3.5rem",
											padding: "0.2rem",
											fontSize: "0.7rem",
											fontWeight: "bold",
											fontFamily: "'Orbitron', sans-serif",
											color: "#ffffff",
											margin: "0.2rem",
											lineHeight: "1",
											height: "2rem",
											backgroundColor: contractType === "higherLower" ? "#4599d9" : slTpButtonColor, // Cambiar color si está activo
											textShadow: "0 0 1px #fff",
											boxShadow:
												contractType === "higherLower"
													? "0 0 5px #4599d9, 0 0 10px #4599d9"
													: slTpButtonColor === "#808080"
													? "0 0 5px #808080, 0 0 10px #808080"
													: "0 0 5px #4599d9, 0 0 10px #4599d9",
											"&:hover": {
												backgroundColor: slTpButtonColor === "#808080" ? "#696969" : "#01a79e",
												boxShadow: "0 0 15px #4599d9, 0 0 30px #4599d9",
											},
											"&:active": {
												backgroundColor: "#4599d9", // Color azul cuando el botón está activo
												boxShadow: "0 0 5px #4599d9, 0 0 10px #4599d9",
											},
										}}
										onClick={async () => {
											handleContractType("higherLower");
										}}>
										HIGHER LOWER
									</MUIButton>

									<MUIButton
										variant="contained"
										sx={{
											width: "3.5rem",
											padding: "0.2rem",
											fontSize: "0.6rem",
											fontWeight: "bold",
											fontFamily: "'Orbitron', sans-serif",
											color: "#ffffff",
											margin: "0.2rem",
											lineHeight: "1",
											height: "2rem",
											backgroundColor: contractType === "touchNoTouch" ? "#4599d9" : slTpButtonColor, // Cambiar color si está activo
											textShadow: "0 0 1px #fff",
											boxShadow:
												contractType === "touchNoTouch"
													? "0 0 5px #4599d9, 0 0 10px #4599d9"
													: slTpButtonColor === "#808080"
													? "0 0 5px #808080, 0 0 10px #808080"
													: "0 0 5px #4599d9, 0 0 10px #4599d9",
											"&:hover": {
												backgroundColor: slTpButtonColor === "#808080" ? "#696969" : "#01a79e",
												boxShadow: "0 0 15px #4599d9, 0 0 30px #4599d9",
											},
											"&:active": {
												backgroundColor: "#4599d9", // Color azul cuando el botón está activo
												boxShadow: "0 0 5px #4599d9, 0 0 10px #4599d9",
											},
										}}
										onClick={async () => {
											handleContractType("touchNoTouch");
										}}>
										TOUCH NOTOUCH
									</MUIButton>

									<MUIButton
										variant="contained"
										sx={{
											width: "3.5rem",
											padding: "0.2rem",
											fontSize: "0.7rem",
											fontWeight: "bold",
											fontFamily: "'Orbitron', sans-serif",
											color: "#ffffff",
											margin: "0.1rem",
											lineHeight: "1",
											height: "2rem",
											backgroundColor: contractType === "accumulators" ? "#4599d9" : slTpButtonColor, // Cambiar color si está presionado
											textShadow: "0 0 1px #fff",
											boxShadow:
												contractType === "accumulators"
													? "0 0 5px #4599d9, 0 0 10px #4599d9"
													: slTpButtonColor === "#808080"
													? "0 0 5px #808080, 0 0 10px #808080"
													: "0 0 5px #4599d9, 0 0 10px #4599d9",
											"&:hover": {
												backgroundColor: slTpButtonColor === "#808080" ? "#696969" : "#01a79e",
												boxShadow: "0 0 15px #4599d9, 0 0 30px #4599d9",
											},
											"&:active": {
												backgroundColor: "#4599d9", // Color azul cuando el botón está activo
												boxShadow: "0 0 5px #4599d9, 0 0 10px #4599d9",
											},
										}}
										onClick={() => handleContractType("accumulators")}>
										ACCUM
									</MUIButton>
								</Box>
							</Box>
						</Rnd>
					)}
				</ThemeProvider>
			)}
		</>
	);
}

export const buyOptions = MyDraggableComponent.buyOptions;
